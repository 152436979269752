import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import OrderHub from './[:idFromPlatform]';

export default {
  path: 'order',
  loader: nonRenderingRouteLoader({
    path: `/:store/order/${OrderHub.path}`,
    end: false,
  }),
  children: [OrderHub],
} satisfies RouteObject;
