import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Field from '~/components/form-controls/field';
import RadioGroup, {
  ButtonOption,
} from '~/components/form-controls/radio-group';
import { useIsLoading } from '~/utils/routing';
import {
  INPUT_NAME,
  useReasonDetailActionResult,
} from './reason-detail-action';
import { useReasonDetailData } from './reason-detail-loader';

export default function ClaimReasonDetail() {
  const isLoading = useIsLoading();
  const {
    reasonDetailOptions,
    claim: { reason: selectedReason },
  } = useReasonDetailData();
  const actionResult = useReasonDetailActionResult();

  return (
    <Card headline="Got it. Can you provide a little more detail?">
      <Form className="flex flex-col" method="post" name="claimReasonDetail">
        <RadioGroup className="mt-2" label="Select a reason" showLabel={false}>
          {reasonDetailOptions.map((reason) => (
            <ButtonOption
              key={reason.id}
              value={reason.id}
              name={INPUT_NAME}
              defaultChecked={reason.id === selectedReason?.detail?.id}
              required
            >
              {reason.name}
            </ButtonOption>
          ))}
        </RadioGroup>
        {actionResult?.ok === false && (
          <Field.HelpText error>{actionResult.message}</Field.HelpText>
        )}
        <Submit className="mt-6" loading={isLoading}>
          Next
        </Submit>
      </Form>
    </Card>
  );
}
