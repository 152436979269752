import { ClaimType } from '~/utils/constants';
import { isAppEmbedded } from '~/utils/isAppEmbedded';
import {
  createLoaderDataHook,
  json,
  redirect,
  registrationLoader,
} from '~/utils/routing';
import { RegistrationState } from '~/utils/types';

export type OverviewRegisterLoaderData = {
  storeUrl?: string;
  isStartWarrantyClaimEnabled: boolean;
  registration: NonNullable<RegistrationState['registration']>;
  isEmbedded: boolean;
};

export default registrationLoader(({ context }) => {
  const { registrations, storeUrl } = context.settings;
  const { isStartWarrantyClaimEnabled } = registrations;

  const isEmbedded = isAppEmbedded();

  // the new registration created in the previous action
  const { registration } = context;

  if (!registration) return redirect(`..`);

  if (registration.referrer && isStartWarrantyClaimEnabled)
    return redirect(
      `../../order/${registration.orderIdFromPlatform}/${ClaimType.warranty}`,
    );

  return json<OverviewRegisterLoaderData>({
    registration,
    storeUrl,
    isStartWarrantyClaimEnabled,
    isEmbedded,
  });
});

export const useRegisterOverviewData =
  createLoaderDataHook<OverviewRegisterLoaderData>();
