import { z } from 'zod';
import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import { useRouteError } from '~/utils/routing';

const errorSchema = z.object({
  message: z.string().startsWith('RefundOptionsErrorBoundary:'),
  cause: z.object({
    orderIdFromPlatform: z.string(),
  }),
});

export default function ErrorBoundary() {
  const error = useRouteError();

  const errorData = errorSchema.safeParse(error);

  // * this is an error we don't know how to handle, bubble up
  if (!errorData.success) {
    throw error;
  }

  return (
    <Card headline="Need help?">
      <p>
        It looks like we can&apos;t handle this return automatically, please
        reach out to our team.
      </p>
      <LinkButton
        className="mt-6 w-full md:w-1/2"
        variant="outlined"
        to={`../order/${errorData.data.cause.orderIdFromPlatform}`}
      >
        Back to Order
      </LinkButton>
    </Card>
  );
}
