import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

const schema = zfd.formData({
  items: zfd.repeatable(z.array(zfd.numeric()).min(1)),
});

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  if (!context.reason) {
    return error(new Error('Context Error: Reason not found'));
  }

  const result = schema.safeParse(formData);

  if (!result.success) {
    return json<ActionResult>({
      ok: false,
      message: 'Please select the order items affected.',
    });
  }

  context.setItemIds(result.data.items);

  return redirect(`../meta`);
});

export const useItemsActionData = createActionResultHook<ActionResult>();
