import { CrewUi } from 'corso-types';
import { addBreadcrumb } from '~/utils/observability';
import {
  createLoaderDataHook,
  json,
  registrationLoader,
} from '~/utils/routing';
import { RegistrationContext } from '~/utils/types';

export type RegisterLoaderData = {
  shouldCollectProofOfPurchase: boolean;
  shouldCollectAddress: boolean;
  referrer?: string;
  storeSalesChannels: RegistrationContext['settings']['registrations']['storeSalesChannels'];
  customFields: RegistrationContext['settings']['registrations']['customFields'];
  draft: RegistrationContext['draftRegistration'];
};

export default registrationLoader(({ context, request }) => {
  const {
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
    customFields,
  } = context.settings.registrations;

  const referrer = new URL(request.url).searchParams.get(
    CrewUi.REF_QUERY_PARAM,
  );

  // Temporary log to debug store sales channels malformation error
  addBreadcrumb({
    message: 'Registration Loader Store Sales Channels',
    level: 'info',
    data: {
      storeSalesChannels,
      defaultValue: context.draftRegistration?.storeSalesChannel,
    },
  });

  return json<RegisterLoaderData>({
    customFields,
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
    referrer: referrer ?? undefined,
    draft: context.draftRegistration,
  });
});

export const useRegisterData = createLoaderDataHook<RegisterLoaderData>();
