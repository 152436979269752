import { RouteObject } from 'react-router-dom';
import OrderClaim from './[:claimType]';
import Address from './address';
import NewRequest from './new-request';
import OrderHub from './order-component';
import loader, { ID } from './order-loader';
import RefundOptions from './refund-options';
import ReturnMethods from './return-methods';
import Review from './review';

export default {
  // The ID is used for the useRouteLoaderData hook in loader file, need to match up.
  id: ID,
  path: ':idFromPlatform',
  loader,
  children: [
    {
      id: 'orderHub',
      index: true,
      Component: OrderHub,
    },
    NewRequest,
    OrderClaim,
    RefundOptions,
    Address,
    ReturnMethods,
    Review,
  ],
} satisfies RouteObject;
