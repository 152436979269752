import { Form } from 'react-router-dom';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Field from '~/components/form-controls/field';

import FileUploader from '~/components/form-controls/file-uploader';
import TextArea from '~/components/form-controls/textarea';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useMetaActionResult } from './meta-action';
import { useMetaLoaderData } from './meta-loader';

export default function ReorderMeta() {
  const { requiredImgUploadCount, uploadImage, ...values } =
    useMetaLoaderData();
  const actionResult = useMetaActionResult();
  const isLoading = useIsLoading();

  return (
    <Page headline="Anything else you can tell us?">
      <Card>
        <Form
          method="POST"
          encType="multipart/form-data"
          className="flex flex-col gap-6"
        >
          <TextArea
            id="noteFromCustomer"
            name="noteFromCustomer"
            label="Additional comments"
            defaultValue={values.noteFromCustomer}
          />

          {uploadImage && (
            <FileUploader
              accept={['image/jpeg', 'image/png', 'image/webp']}
              name="assets"
              requiredCount={requiredImgUploadCount}
              value={values.assets ?? []}
              description="Please upload clear images indicating damaged items."
            />
          )}
          <input
            type="hidden"
            name="requiredImgUploadCount"
            value={requiredImgUploadCount}
          />

          {actionResult?.ok === false && (
            <Field.HelpText error>{actionResult.message}</Field.HelpText>
          )}
          <Submit loading={isLoading}>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
