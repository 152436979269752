import { Address, OrderAddress } from '~/utils/types';

type GenericAddress = Partial<Address> | OrderAddress;
export default function AddressDisplay<T extends GenericAddress>({
  address,
}: {
  address: T;
}) {
  return (
    <address className="italic">
      <span>
        {address?.firstName} {address?.lastName}
      </span>
      <br />
      <span>{address?.line1}</span>
      <br />
      {address?.line2 && (
        <>
          {address.line2}
          <br />
        </>
      )}
      <span>
        {address?.city}, {address?.stateOrProvinceCode} {address?.postalCode}
      </span>
      <br />
      <span>{address?.countryCode}</span>
    </address>
  );
}
