import { RouteObject } from 'react-router-dom';
import ReasonSteps from './[:reason]';
import Address from './address';
import action from './reason-action';
import Reason from './reason-component';
import loader from './reason-loader';
import Review from './review';
import ShippingIssue from './shipping-issue';

export default {
  path: 'new',
  children: [
    {
      index: true,
      Component: Reason,
      loader,
      action,
    },
    ReasonSteps,
    Address,
    Review,
    ShippingIssue,
  ],
} satisfies RouteObject;
