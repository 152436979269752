import {
  AppTypeEnum as AppType,
  CrewClaimReasonCategoryCode as ClaimReasonCategoryCode,
  CrewClaimTypeEnum as ClaimType,
  ClaimReasonEnum as GspClaimReason,
  CrewIncentiveTypeEnum as IncentiveType,
  CrewClaimResolutionMethodEnum as ResolutionMethod,
  ShipmentMethod as ReturnShipmentType,
  CrewClaimRollupStatusCode as RollupStatusCode,
} from 'corso-types';

export {
  AppType,
  ClaimReasonCategoryCode,
  ClaimType,
  GspClaimReason,
  IncentiveType,
  ResolutionMethod,
  ReturnShipmentType,
  RollupStatusCode,
};

export const CORSO_URL = 'https://corso.com';

export const DEFAULT_PROTECTED_ICON_IMG_URL =
  'https://cdn.corso.com/img/corso-gsp-prod-img-sm.png';

export const DEFAULT_UNPROTECTED_ICON_IMG_URL =
  'https://cdn.corso.com/img/shipping-truck-icon-img.png';

export const PRODUCT_NOT_FOUND_IMG_URL =
  'https://cdn.corso.com/img/image-not-available.jpeg';

export const ResolutionMethodLabels = {
  [ResolutionMethod.refund]: 'Refund',
  [ResolutionMethod.variantExchange]: 'Exchange',
  [ResolutionMethod.giftCard]: 'Gift Card',
  [ResolutionMethod.repair]: 'Repair',
  [ResolutionMethod.replacementOrder]: 'Replacement Order',
  [ResolutionMethod.warrantyReview]: 'Warranty',
} as const satisfies Record<ResolutionMethod, string>;

export const GspReasonLabels = {
  [GspClaimReason.damaged]: 'It was damaged',
  [GspClaimReason.stolen]: 'It was marked delivered but was never received',
  [GspClaimReason.lost]: 'It was lost in transit',
  [GspClaimReason.wrongItem]: 'I received the wrong item',
  [GspClaimReason.missingItem]: 'An item is missing from my order',
  [GspClaimReason.qualityIssue]: 'There is a quality issue with my item',
  [GspClaimReason.incorrectAddress]: 'The wrong address was used',
} as const satisfies Record<GspClaimReason, string>;

export const ClaimRollupStatuses = {
  [RollupStatusCode.inProgress]: 'Requested',
  [RollupStatusCode.readyForReview]: 'In Review',
  [RollupStatusCode.completed]: 'Completed',
} as const satisfies Record<RollupStatusCode, string>;

export enum ExchangeMethod {
  standard = 'standard',
  instant = 'instant',
}

/**
 * iana top-level media types
 * @see https://www.iana.org/assignments/top-level-media-types/top-level-media-types.xhtml
 */
export enum MediaType {
  application = 'application',
  audio = 'audio',
  example = 'example',
  font = 'font',
  haptics = 'haptics',
  image = 'image',
  message = 'message',
  model = 'model',
  multipart = 'multipart',
  text = 'text',
  video = 'video',
}
