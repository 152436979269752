import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { type MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './button';

export default function PaginationControls({
  currentPage,
  pageCount,
  onNext,
  onPrevious,
  className,
}: {
  currentPage: number;
  pageCount: number;
  onNext: MouseEventHandler;
  onPrevious: MouseEventHandler;
  className?: string;
}) {
  const baseStyles = 'border border-outline px-2 py-2';

  return (
    <div
      className={twMerge(
        'inline-flex max-w-fit rounded-md bg-white text-sm text-gray-400 shadow-sm',
        className,
      )}
    >
      <Button
        variant="outlined"
        className={twMerge(
          baseStyles,
          'rounded-l-md rounded-r-none shadow-none focus:z-10',
        )}
        onClick={onPrevious}
        aria-label="View Previous Page"
      >
        <ChevronLeftIcon className="h-5 w-5" title="Previous Page" />
      </Button>
      <span
        className={twMerge(baseStyles, 'w-30 -ml-px text-center text-gray-800')}
      >
        {`${currentPage} of ${pageCount}`}
      </span>
      <Button
        variant="outlined"
        type="button"
        className={twMerge(
          baseStyles,
          '-ml-px rounded-l-none rounded-r-md shadow-none focus:z-10',
        )}
        onClick={onNext}
        aria-label="View Next Page"
      >
        <ChevronRightIcon className="h-5 w-5" title="Next Page" />
      </Button>
    </div>
  );
}
