import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

enum Variants {
  DEFAULT = 'default',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
}

type BadgeProps = PropsWithChildren<{
  className?: string;
  variant?: `${Variants}`;
}>;

const variantStyles: Record<Variants, string> = {
  [Variants.DEFAULT]: 'bg-gray-50 border-outline text-gray-800',
  [Variants.Success]: 'bg-green-100 border-green-300 text-green-800',
  [Variants.Warning]: 'bg-yellow-100 border-yellow-300 text-yellow-800',
  [Variants.Danger]: 'bg-red-100 border-red-300 text-red-800',
};

export default function Badge({
  children,
  className,
  variant = 'default',
}: BadgeProps) {
  return (
    <span
      className={twMerge(
        'inline-flex max-w-max items-center gap-x-1 whitespace-nowrap rounded-full border px-2 py-[0.15rem] text-xs',
        variantStyles[variant],
        className,
      )}
    >
      {children}
    </span>
  );
}
