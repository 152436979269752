import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { GspStore } from '~/stores/gsp';
import api from '~/utils/api';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export enum FormKeys {
  EmailOrPostalCode = 'emailOrPostalCode',
  OrderNo = 'orderNo',
}

const formSchema = zfd.formData(
  z.object({
    [FormKeys.OrderNo]: zfd.text(z.string().min(1)),
    [FormKeys.EmailOrPostalCode]: zfd.text(z.string().min(1)),
  }),
);

export default crewAction(({ formData, context }) => {
  const { storefrontId } = context.settings;
  const formResult = formSchema.parse(formData);
  const query = formResult;

  return api
    .fetchCrewOrder({
      params: { storefrontId },
      query,
    })
    .then((order) => {
      context.reset();
      context.setOrder(order);

      //* prep the gsp flow with the order
      GspStore.reset();
      GspStore.setOrder(order);

      return redirect(`order/${order.idFromPlatform}`, StatusCodes.SEE_OTHER);
    })
    .catch((error) =>
      json<ActionResult>({
        ok: false,
        message: error instanceof Error ? error.message : String(error),
      }),
    );
});

export const useOrderLookupActionResult =
  createActionResultHook<ActionResult>();
