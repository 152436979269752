import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { ClaimType } from '~/utils/constants';
import { useNewRequestLoaderData } from './new-request-loader';

export default function NewRequest() {
  const { claimTypeSelectionDetailText } = useNewRequestLoaderData();
  return (
    <Page headline="Start A New Request">
      <Card headline="Which of the following best fits your needs?">
        <p>{claimTypeSelectionDetailText}</p>
        <div className="flex flex-col gap-4 sm:flex-row">
          <LinkButton
            className="w-full"
            relative="path"
            to={`../${ClaimType.return}`}
          >
            Return
          </LinkButton>
          <LinkButton
            className="w-full"
            relative="path"
            to={`../${ClaimType.warranty}`}
          >
            Warranty
          </LinkButton>
        </div>
      </Card>
    </Page>
  );
}
