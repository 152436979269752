import { z } from 'zod';
import { zfd } from 'zod-form-data';
import { MediaType } from './constants';

export const filesFormSchema = z.object({
  files: zfd.repeatable(
    z
      .array(z.instanceof(File))
      .optional()
      .default([])
      .transform((files) => files.filter((file) => file.size > 0)),
  ),
});

export const determineFileType = (file: File): MediaType => {
  const type = file.type.split('/')[0];
  const parsedType = z.nativeEnum(MediaType).safeParse(type);

  if (!parsedType.success) {
    // The media type is not supported -- so what type is it? The enum is pretty exhaustive.
    throw new Error(`Unsupported file ${file.name} type: ${type}.`);
  }

  return parsedType.data;
};
