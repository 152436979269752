import { RouteObject } from 'react-router-dom';
import Component from './draft-layout-component';
import loader from './draft-layout-loader';
import followUp from './follow-up';
import meta from './meta';
import quantity from './quantity';
import reason from './reason';
import reasonDetail from './reason-detail';
import resolution from './resolution';
import shippingProtection from './shipping-protection';

export default {
  id: 'draftLayout',
  path: ':draftId',
  loader,
  Component,
  children: [
    quantity,
    reason,
    reasonDetail,
    followUp,
    meta,
    resolution,
    shippingProtection,
  ],
  shouldRevalidate: () => false,
} satisfies RouteObject;
