import { LoaderFunctionArgs } from 'react-router-dom';
import { CrewStore } from '~/stores/crew';
import { CORSO_URL } from '~/utils/constants';
import { json, redirect } from '~/utils/routing';
import { defaultTheme, setTheme } from '~/utils/theme';

const getTheme = (store?: string) =>
  store ?
    CrewStore.initAsync(store).then(() => CrewStore.settings.theme)
  : Promise.resolve(defaultTheme);

const removeSplashScreen = () => {
  const body = document.getElementById('crew');

  if (!body) {
    throw new Error('Body not found');
  }

  body.classList.add('ready');
};

export default async ({ params: { store } }: LoaderFunctionArgs) =>
  getTheme(store)
    .then(setTheme)
    .then(removeSplashScreen)
    .then(() => json({ ok: true }))
    .catch(() => redirect(CORSO_URL));
