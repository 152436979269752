import { twMerge } from 'tailwind-merge';
import Card from '~/components/card';
import Page from '~/components/page';
import {
  EntryPoint,
  useProtectionClaimLoaderData,
} from './protection-claim-loader';

const headlineMap = {
  [EntryPoint.new]: 'We received your information',
  [EntryPoint.existing]: 'A Request Already Exists',
  [EntryPoint.deepLink]: 'Request Details',
} as const satisfies Record<EntryPoint, string>;

const titleMap = {
  [EntryPoint.new]: (
    <>
      You should be receiving an email with the next steps. If you don&apos;t
      hear from us, please reach out at{' '}
      <a
        className="text-primary-on-container hover:underline"
        href="mailto:support@corso.com"
      >
        support@corso.com
      </a>
    </>
  ),
  [EntryPoint.existing]: (
    <>
      We found your order, but it&apos;s already had a request submitted. If you
      have any questions, please contact us at{' '}
      <a
        className="text-primary-on-container hover:underline"
        href="mailto:support@corso.com"
      >
        support@corso.com
      </a>
      .
    </>
  ),
  [EntryPoint.deepLink]: (
    <>
      Here are the details of your request. If you have any questions, please
      contact us at{' '}
      <a
        className="text-primary-on-container hover:underline"
        href="mailto:support@corso.com"
      >
        support@corso.com
      </a>
      .{' '}
    </>
  ),
} as const satisfies Record<EntryPoint, JSX.Element>;

const statusMap = {
  Closed_Denied: 'Denied',
  Closed_Refunded: 'Refunded',
  Closed_Reordered: 'Reordered',
  Closed_Resolved: 'Resolved',
  InProgress: 'In Progress',
  Open: 'Open',
} as const satisfies Record<
  ReturnType<typeof useProtectionClaimLoaderData>['status'],
  string
>;

export default function Claim() {
  const { id, status, createdOn, entryPoint } = useProtectionClaimLoaderData();

  return (
    <Page headline={headlineMap[entryPoint]}>
      <p className="mt-1 text-center text-gray-500">{titleMap[entryPoint]}</p>
      <Card headline="Shipping Support Request">
        <dl
          className={twMerge(
            'grid grid-cols-2 divide-y divide-outline *:py-2 [&>dd]:text-right [&>dd]:text-gray-700',
            // this is to fix tailwindcss issue with using divide in grid
            '[&>dd:first-of-type]:!border-t-0',
          )}
        >
          <dt id="claimId">Confirmation</dt>
          <dd aria-labelledby="claimId">#{id}</dd>
          <dt id="status">Status</dt>
          <dd aria-labelledby="status">{statusMap[status]}</dd>
          <dt id="createdDate">Created</dt>
          <dd aria-labelledby="createdDate">{createdOn}</dd>
        </dl>
      </Card>
    </Page>
  );
}
