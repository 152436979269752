import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { Address, OrderAddress } from '~/utils/types';

export type LoaderData = {
  address: Address | OrderAddress | null;
};

export default gspLoader(({ params: { store = '' }, context: { order } }) =>
  order ?
    json<LoaderData>({
      address: order.shippingAddress,
    })
  : redirect(`/${store}`),
);

export const useEditAddressData = createLoaderDataHook<LoaderData>();
