import { CrewClaimTypeEnum } from 'corso-types';
import { Outlet } from 'react-router-dom';
import Card from '~/components/card';
import LineItem from '~/components/line-item';
import Page from '~/components/page';
import { useClaimLoaderData } from './draft-layout-loader';

export default function DraftClaimLayout() {
  const {
    claim: { claimType, lineItem },
  } = useClaimLoaderData();

  const draftHeadlineLookup = {
    [CrewClaimTypeEnum.return]: 'The product you will be returning',
    [CrewClaimTypeEnum.warranty]: 'Item requested for warranty',
  } satisfies Record<CrewClaimTypeEnum, string>;

  return (
    <Page
      headline="What happened and how can we make it right?"
      showHeadline={false}
    >
      <Card headline={draftHeadlineLookup[claimType]}>
        <LineItem
          name={lineItem.name ?? ''}
          price={lineItem.unitPriceDisplay}
          imgSrc={lineItem.imgUrl}
          options={lineItem.optionsFromPlatform}
          footnote={
            lineItem.return.isExchangeOnly && (
              <span className="text-red-700">Exchange Only</span>
            )
          }
          variant="small"
        />
      </Card>
      <Outlet />
    </Page>
  );
}
