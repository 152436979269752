import { twMerge } from 'tailwind-merge';
import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { ClaimType } from '~/utils/constants';
import { formatDate } from '~/utils/formatters';
import { useRegisterOverviewData } from './register-overview-loader';

export default function RegisterOverview() {
  const { isStartWarrantyClaimEnabled, registration, storeUrl, isEmbedded } =
    useRegisterOverviewData();

  const {
    externalId,
    createdOn,
    email,
    orderIdFromPlatform: idFromPlatform,
  } = registration;

  const showActions = isStartWarrantyClaimEnabled || storeUrl;

  return (
    <Page headline="Registration Created">
      <Card>
        <p>
          <span className="font-sm text-gray-800">
            Thank you your registration, please keep this number for your
            records.
          </span>
        </p>
        <dl
          className={twMerge(
            'grid grid-cols-2 divide-y divide-outline *:py-2 [&>dd]:text-right [&>dd]:text-gray-700',
            // this is to fix tailwindcss issue with using divide in grid
            '[&>dd:first-of-type]:!border-t-0',
          )}
        >
          <dt id="externalId">Confirmation</dt>
          <dd aria-labelledby="externalId">#{externalId}</dd>

          <dt id="email">Email</dt>
          <dd aria-labelledby="email">{email}</dd>

          <dt id="createdDate">Created</dt>
          <dd aria-labelledby="createdDate">{formatDate(createdOn)}</dd>
        </dl>

        {showActions && (
          <div className="gap mt-2 flex justify-between gap-6 *:flex-1 *:whitespace-nowrap">
            {storeUrl && !isEmbedded && (
              <LinkButton to={storeUrl} variant="outlined">
                Back To Merchant
              </LinkButton>
            )}

            {/* can we skip the hub and go straight to warranty here? */}
            {isStartWarrantyClaimEnabled && (
              <LinkButton
                variant="filled"
                to={`../../order/${idFromPlatform}/${ClaimType.warranty}`}
              >
                Start A Claim
              </LinkButton>
            )}
          </div>
        )}
      </Card>
    </Page>
  );
}
