import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import { useShippingProtectionData } from './shipping-protection-loader';

export default function ShippingProtection() {
  const { shippingProtectionUrl, reasonId } = useShippingProtectionData();

  return (
    <Card className="mb-8" headline="Quickly Resolve Your Shipping Issue">
      <div className="flex flex-col gap-6">
        <p>
          This order has shipping protection for moments like this. If your
          package was lost, stolen, or damaged in transit, you can simply
          request a replacement.
        </p>
        <div className="mt-4 flex flex-col md:mt-0">
          <LinkButton
            className="md:w-1/2"
            variant="filled"
            to={shippingProtectionUrl}
          >
            Request Replacement
          </LinkButton>
          <LinkButton
            variant="text"
            className="mx-auto mt-8 w-48 justify-start px-0 focus:ring-0 focus:ring-transparent focus:ring-offset-0 md:mx-0"
            to={`../reason/${String(reasonId)}`}
          >
            Or continue with return
          </LinkButton>
        </div>
      </div>
    </Card>
  );
}
