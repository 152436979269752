import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import Overview from './overview';

export default {
  path: 'claim',
  loader: nonRenderingRouteLoader({
    path: `/:store/claim/${Overview.path}`,
    end: false,
  }),
  children: [Overview],
} satisfies RouteObject;
