import Card from '~/components/card';
import Chip from '~/components/chip';
import ClaimLineItem from '~/components/claim-line-item';
import LineItem from '~/components/line-item';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import PaginationControls from '~/components/pagination-controls';
import usePagination from '~/components/use-pagination';
import { useClaimOverviewData } from './claim-overview-loader';
import { QrCode } from './qr-code';

function LineItemChips({ chips }: { chips: string[] }) {
  if (chips.length === 0) {
    return null;
  }

  return (
    <div className="flex gap-1">
      {chips.map((chip) => (
        <Chip key={chip}>{chip}</Chip>
      ))}
    </div>
  );
}

function ResolutionSummary() {
  const { resolutionSummary } = useClaimOverviewData();

  if (
    !resolutionSummary?.exchangeOrder &&
    !resolutionSummary?.refund &&
    !resolutionSummary?.giftCard
  ) {
    return null;
  }

  const { exchangeOrder, refund, giftCard } = resolutionSummary;

  const title =
    exchangeOrder ?
      `New order number: ${exchangeOrder.orderNameFromPlatform}`
    : '';

  const refundChips = [];

  if (refund && refund.shippingFee.value > 0) {
    refundChips.push(`Refund Shipping Fee: -${refund.shippingFee.display}`);
  }

  if (refund && refund.handlingFee.value > 0) {
    refundChips.push(`Refund Handling Fee: -${refund.handlingFee.display}`);
  }

  const giftCardChips = [];

  if (giftCard && giftCard.incentiveAmount.value > 0) {
    giftCardChips.push(`Incentive: +${giftCard.incentiveAmount.display}`);
  }

  return (
    <Card
      testId="resolution-summary-card"
      headline="What you are getting"
      title={title}
    >
      <div className="divide-y divide-outline [&>:first-child]:pt-0 [&>:last-child]:last:pb-0 [&>article]:py-4">
        {exchangeOrder?.lineItems.map((item) => (
          <LineItem
            key={item.id}
            name={item.name ?? 'n/a'}
            options={item.optionsFromPlatform}
            imgSrc={item.imgUrl}
            variant="small"
            quantity={item.quantity}
          />
        ))}

        {refund && (
          <LineItem
            name="Refund To Original Payment Method"
            imgSrc="https://cdn.corso.com/img/crew-refunds.png"
            variant="small"
            price={refund.amount.display}
            footnote={<LineItemChips chips={refundChips} />}
          />
        )}

        {giftCard && (
          <LineItem
            name="Gift Card"
            imgSrc="https://cdn.corso.com/img/crew-gift-card.png"
            variant="small"
            price={giftCard.amount.display}
            footnote={<LineItemChips chips={giftCardChips} />}
          />
        )}
      </div>
    </Card>
  );
}

// only covers variant exchanges, potentially expand to show
function RequestSummary() {
  const { requestSummary } = useClaimOverviewData();

  if (!requestSummary?.variantExchangeLineItems.length) return null;

  const { variantExchangeLineItems } = requestSummary;

  return (
    <Card testId="request-summary-card" headline="What you are getting">
      <div className="divide-y divide-outline [&>:first-child]:pt-0 [&>:last-child]:last:pb-0 [&>article]:py-4">
        {variantExchangeLineItems.map((item) => (
          <LineItem
            key={item.idFromPlatform}
            name={item.name ?? 'n/a'}
            options={item.optionsFromPlatform}
            imgSrc={item.imgUrl}
            variant="small"
            quantity={item.quantity}
          />
        ))}
      </div>
    </Card>
  );
}

function GreenReturnItems() {
  const { greenReturns: { items, title, headline } = {} } =
    useClaimOverviewData();

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Card testId="green-returns-card" headline={headline} title={title}>
      <div className="divide-y divide-outline [&>:first-child]:pt-0 [&>:last-child]:last:pb-0 [&>article]:py-4">
        {items.map((item) => (
          <ClaimLineItem
            key={item.id}
            name={item.originalStoreOrderLineItem.name ?? 'n/a'}
            imgUrl={item.originalStoreOrderLineItem.imgUrl}
            quantity={item.quantity}
            price={item.cost}
            productOptions={item.originalStoreOrderLineItem.optionsFromPlatform}
            requestedResolution={item.requestedResolutionMethodEnum}
            claimLineItemNoteToCustomer={item.noteToCustomer}
          />
        ))}
      </div>
    </Card>
  );
}

function ReturnShipments() {
  const {
    returnShipments,
    claimExternalId,
    storeUrl,
    claimRollup,
    hideLabelDownloadWhenQrCodeIsPresent,
    isEmbedded,
  } = useClaimOverviewData();
  const {
    currentPage,
    data: shipment,
    next,
    previous,
  } = usePagination(returnShipments);

  if (!shipment) return null;

  const shouldHideLabelDownload =
    hideLabelDownloadWhenQrCodeIsPresent && shipment.qrCodeDetail;

  const {
    headline,
    title,
    isExpired,
    qrCodeDetail,
    claimLineItems,
    returnShipmentPdfAssetUrl,
    downloadLabel,
    fromAddress,
  } = shipment;

  return (
    <Card
      testId="return-shipments-card"
      headline={headline}
      title={title}
      headerContent={
        returnShipments.length > 1 && (
          <PaginationControls
            className="w-full justify-self-end"
            currentPage={currentPage}
            pageCount={returnShipments.length}
            onNext={next}
            onPrevious={previous}
          />
        )
      }
    >
      <div className="divide-y divide-outline [&>:first-child]:pt-0 [&>:last-child]:last:pb-0 [&>article]:py-4">
        {claimLineItems.map(
          ({
            id,
            quantity,
            status,
            cost,
            originalStoreOrderLineItem: { name, imgUrl, optionsFromPlatform },
            requestedResolutionMethodEnum,
            noteToCustomer: claimLineItemNoteToCustomer,
          }) => (
            <ClaimLineItem
              key={id}
              name={name}
              className="mb-4 border-b border-outline pb-4 last:mb-0 last:border-none last:pb-0"
              price={cost}
              imgUrl={imgUrl}
              quantity={quantity}
              status={status.name}
              rollupStatus={claimRollup.code}
              requestedResolution={requestedResolutionMethodEnum}
              claimLineItemNoteToCustomer={claimLineItemNoteToCustomer}
              productOptions={optionsFromPlatform}
            />
          ),
        )}
      </div>

      {!isExpired && (
        <>
          {qrCodeDetail && (
            <QrCode
              details={{
                ...qrCodeDetail,
                confirmation: claimExternalId,
                fromAddress,
              }}
            />
          )}

          <div className="flex flex-col gap-4 md:flex-row">
            {returnShipmentPdfAssetUrl && !shouldHideLabelDownload && (
              <LinkButton
                className="w-full md:w-1/2"
                variant="outlined"
                to={returnShipmentPdfAssetUrl}
                target="_blank"
              >
                {downloadLabel}
              </LinkButton>
            )}
            {storeUrl && !isEmbedded && (
              <LinkButton
                className="w-full md:w-1/2"
                to={storeUrl}
                variant="outlined"
                target="_blank"
                rel="noreferrer"
              >
                Back To Our Website
              </LinkButton>
            )}
          </div>
        </>
      )}
    </Card>
  );
}

export default function ReturnClaim() {
  const { claimExternalId, isCompleted, noteToCustomer } =
    useClaimOverviewData();

  return (
    <Page
      headline={
        isCompleted ? 'Your request is complete' : 'We received your request'
      }
      title={`Confirmation number: #${claimExternalId}`}
    >
      {noteToCustomer && isCompleted && (
        <Card headline="Note">
          <p className="text-sm text-gray-600">{noteToCustomer}</p>
        </Card>
      )}

      <ReturnShipments />
      <GreenReturnItems />

      {isCompleted ?
        <ResolutionSummary />
      : <RequestSummary />}
    </Page>
  );
}
