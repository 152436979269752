import AddressDisplay from '~/components/address';
import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { useAddressData } from './address-loader';

export default function Address() {
  const { address } = useAddressData();

  return (
    <Page headline="Does this look correct?">
      <Card>
        <div className="flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between">
          <AddressDisplay address={address} />
          <LinkButton to="edit" className="w-full sm:w-48">
            Change Address
          </LinkButton>
        </div>

        <LinkButton to="../review" variant="filled" className="w-full md:w-1/2">
          Next
        </LinkButton>
      </Card>
    </Page>
  );
}
