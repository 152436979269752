import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

enum Variants {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

type VariantStyle = {
  bg: string;
  border: string;
  content: string;
  icon: string;
  title: string;
};

const variantStyles: Record<Variants, VariantStyle> = {
  [Variants.Info]: {
    bg: 'bg-blue-50',
    border: 'border-blue-700',
    content: 'text-blue-700',
    icon: 'text-blue-400',
    title: 'text-blue-800',
  },
  [Variants.Success]: {
    bg: 'bg-green-50',
    border: 'border-green-700',
    content: 'text-green-700',
    icon: 'text-green-400',
    title: 'text-green-800',
  },
  [Variants.Warning]: {
    bg: 'bg-yellow-50',
    border: 'border-yellow-700',
    content: 'text-yellow-700',
    icon: 'text-yellow-400',
    title: 'text-yellow-800',
  },
  [Variants.Error]: {
    bg: 'bg-red-50',
    border: 'border-red-700',
    content: 'text-red-700',
    icon: 'text-red-400',
    title: 'text-red-800',
  },
};

const iconStyles = 'h-5 w-5';
const icons: Record<Variants, ReactNode> = {
  [Variants.Info]: (
    <InformationCircleIcon
      className={twJoin(iconStyles, variantStyles[Variants.Info].icon)}
      aria-hidden="true"
    />
  ),
  [Variants.Success]: (
    <CheckCircleIcon
      className={twJoin(iconStyles, variantStyles[Variants.Success].icon)}
      aria-hidden="true"
    />
  ),
  [Variants.Warning]: (
    <ExclamationTriangleIcon
      className={twJoin(iconStyles, variantStyles[Variants.Warning].icon)}
      aria-hidden="true"
    />
  ),
  [Variants.Error]: (
    <ExclamationCircleIcon
      className={twJoin(iconStyles, variantStyles[Variants.Error].icon)}
      aria-hidden="true"
    />
  ),
};

type Props = {
  title: string;
  children?: ReactNode;
  className?: string;
  variant?: `${Variants}`;
};
export default function Alert({
  title,
  children,
  className,
  variant = Variants.Error,
}: Props) {
  return (
    <div
      className={twMerge(
        'rounded-md border p-4',
        variantStyles[variant].bg,
        variantStyles[variant].border,
        className,
      )}
      role={variant === 'error' ? 'alert' : 'status'}
      aria-live={variant === 'error' ? 'assertive' : 'polite'}
    >
      <div className="flex">
        <div className="flex-shrink-0">{icons[variant]}</div>
        <div className="ml-3">
          <h3
            className={twJoin(
              'text-sm font-medium',
              variantStyles[variant].title,
            )}
          >
            {title}
          </h3>
          {children && (
            <div
              className={twJoin('mt-2 text-sm', variantStyles[variant].content)}
            >
              {children}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
