import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import {
  createActionResultHook,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export enum FormKeys {
  EmailOrPostalCode = 'emailOrPostalCode',
  OrderNo = 'orderNo',
}

const formSchema = zfd.formData(
  z.object({
    [FormKeys.EmailOrPostalCode]: zfd.text(z.string().min(1)),
    [FormKeys.OrderNo]: zfd.text(z.string().min(1)),
  }),
);

export default gspAction(({ formData, context }) => {
  const formResult = formSchema.parse(formData);
  const query = formResult;

  return api
    .fetchProtectionOrder({ query })
    .then((order) => {
      context.reset();
      context.setOrder(order);

      return redirect(`./reorder/${order.idFromPlatform}`);
    })
    .catch((error) =>
      json<ActionResult>({
        ok: false,
        message: error instanceof Error ? error.message : String(error),
      }),
    );
});

export const useOrderLookupActionResult =
  createActionResultHook<ActionResult>();
