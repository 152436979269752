import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  headline: string;
  title?: string;
  showHeadline?: boolean;
  children?: ReactNode;
  className?: string;
};

export default function Page({
  headline,
  title,
  children,
  className = '',
  showHeadline = true,
}: Props) {
  return (
    <section className={twMerge('flex flex-col gap-4', className)}>
      <div className={twMerge('px-4', !showHeadline && 'sr-only')}>
        <h1 className="text-center text-2xl text-gray-800">{headline}</h1>
        {title && <p className="mt-1 text-center text-gray-500">{title}</p>}
      </div>
      {children}
    </section>
  );
}
