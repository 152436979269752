import { Form } from 'react-router-dom';
import AddressDisplay from '~/components/address';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useAddressActionResult } from './address-action';
import { useAddressData } from './address-loader';

export default function ConfirmAddress() {
  const { address } = useAddressData();
  const actionResult = useAddressActionResult();
  const isLoading = useIsLoading();

  return (
    <Page headline="Is this still the right address?">
      <Card>
        <div className="flex flex-col gap-6 sm:flex-row sm:items-center sm:justify-between">
          <AddressDisplay address={address} />
          <LinkButton to="edit" className="w-full sm:w-48">
            Change Address
          </LinkButton>
        </div>
        <Form method="POST">
          {actionResult?.ok === false && (
            <Alert className="mt-6" variant="error" title="Invalid address">
              Please update the address.
            </Alert>
          )}

          {Object.entries(address).map(([key, value]) => (
            <input
              key={key}
              type="hidden"
              name={key}
              defaultValue={value ?? ''}
            />
          ))}
          <Submit className="mt-6" loading={isLoading}>
            Continue
          </Submit>
        </Form>
      </Card>
    </Page>
  );
}
