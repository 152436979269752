import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './button';
import Spinner from './spinner';

type Props = Omit<ComponentProps<typeof Button>, 'type'> & {
  loading?: boolean;
};

export default function Submit({
  children,
  className = '',
  loading,
  disabled,
  ...buttonProps
}: Props) {
  return (
    <Button
      // eslint-disable-next-line react/jsx-props-no-spreading -- since we are extending the button props
      {...buttonProps}
      type="submit"
      disabled={Boolean(loading) || Boolean(disabled)}
      className={twMerge('w-full md:w-1/2', className)}
    >
      {loading ?
        <Spinner className="h-6 w-6 fill-on-primary text-primary" />
      : <span>{children}</span>}
    </Button>
  );
}
