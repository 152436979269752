import { twMerge } from 'tailwind-merge';
import Badge from './badge';

export const FALLBACK_IMAGE =
  'https://cdn.corso.com/img/image-not-available.jpeg';

function Quantity({
  original,
  available,
}: {
  original?: number;
  available?: number;
}) {
  const hasAvailable = available !== undefined && original !== available;

  return original !== undefined ?
      <>
        <span className="sr-only">Quantity</span>
        {hasAvailable && <span>{available} of</span>}
        <span>{original}</span>
      </>
    : <>
        <span className="sr-only">Quantity none</span>
        <span>-</span>
      </>;
}

type Props = {
  name?: string | null;
  src?: string | null;
  quantity?: number;
  remainingReturnableQuantity?: number;
  variant?: 'regular' | 'small' | 'full';
};

export default function ProductImage({
  src,
  name,
  variant,
  quantity,
  remainingReturnableQuantity,
}: Props) {
  return (
    <div
      className={twMerge(
        'relative rounded',
        variant !== 'full' && 'flex-shrink-0 self-start',
      )}
    >
      <img
        src={src ?? ''}
        alt={name ?? 'Product'}
        className={twMerge(
          'size-20 rounded border border-outline object-contain',
          variant === 'small' && 'size-12',
          variant === 'full' && 'size-full',
        )}
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.onerror = null;
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = FALLBACK_IMAGE;
        }}
      />
      {quantity !== undefined && (
        <Badge className="absolute -right-2 -top-2">
          <Quantity
            original={quantity}
            available={remainingReturnableQuantity}
          />
        </Badge>
      )}
    </div>
  );
}
