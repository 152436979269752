// * generally want to limit the imports of corso-types but this component is built specifically for a type from corso-types
import { CrewUi } from 'corso-types';
import Input from './form-controls/input';
import { Select, SelectItem } from './form-controls/select';

type CustomFieldType =
  CrewUi.CollectCustomFieldEvent['params']['customFields'][number];

const lookupInputType = (valueType: CustomFieldType['valueType']) => {
  switch (valueType) {
    case 'Number':
      return 'number';
    case 'Date':
      return 'date';
    default:
      return 'text';
  }
};

export default function CustomField({
  field,
  defaultValue,
  className,
}: {
  field: CustomFieldType;
  defaultValue?: string;
  className?: string;
}) {
  return (
    <>
      {field.valueType === 'Select' ?
        <Select
          id={`${field.id}`}
          className={className}
          // default to true
          required={field.required ?? true}
          description={field.description}
          name={`${field.id}[value]`}
          label={field.displayName}
          defaultValue={defaultValue}
        >
          {field.options.map((option) => (
            <SelectItem key={option} value={option}>
              {option}
            </SelectItem>
          ))}
        </Select>
      : <Input
          id={`${field.id}`}
          name={`${field.id}[value]`}
          className={className}
          description={field.description}
          label={field.displayName}
          type={lookupInputType(field.valueType)}
          required={field.required}
          defaultValue={defaultValue}
        />
      }
      <input type="hidden" name={`${field.id}[type]`} value={field.valueType} />
    </>
  );
}
