import { RouteObject } from 'react-router-dom';
import action from './resolution-action';
import Component from './resolution-component';
import loader from './resolution-loader';

export default {
  id: 'claimResolution',
  path: 'resolution',
  Component,
  action,
  loader,
} satisfies RouteObject;
