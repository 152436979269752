import { Form } from 'react-router-dom';
import Alert from '~/components/alert';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Field from '~/components/form-controls/field';

import FileUploader from '~/components/form-controls/file-uploader';
import Input from '~/components/form-controls/input';

import AddressFields from '~/components/address-fields';
import CustomField from '~/components/custom-field';
import { Select, SelectItem } from '~/components/form-controls/select';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { FormKeys, useRegisterActionResult } from './register-action';
import { useRegisterData } from './register-loader';

export default function Register() {
  const {
    storeSalesChannels,
    shouldCollectProofOfPurchase,
    shouldCollectAddress,
    referrer,
    customFields,
    draft,
  } = useRegisterData();

  const isLoading = useIsLoading();

  const requiredMediaCount = Number(shouldCollectProofOfPurchase);

  const actionResult = useRegisterActionResult();
  const hasErrors = actionResult && !actionResult.ok;

  const showAlert = hasErrors && actionResult.message;
  const showFieldHelpText = hasErrors && !actionResult.message;

  return (
    <Page headline={`Let's grab some quick info`}>
      <Card>
        <Form
          method="post"
          className="flex flex-col gap-6"
          encType="multipart/form-data"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Input
              className="sm:col-span-2"
              id={FormKeys.Email}
              name={FormKeys.Email}
              label="Email Address"
              required
              type="email"
              autoComplete="email"
              defaultValue={draft?.email}
              validationMessages={{
                valueMissing: 'Please enter your email address.',
                typeMismatch: 'Please enter a valid email address.',
              }}
            />

            <AddressFields
              className="sm:col-span-2"
              // default to use firstName and lastName in case we don't have an address from !shouldCollectAddress
              address={
                draft?.address ?? {
                  firstName: draft?.firstName,
                  lastName: draft?.lastName,
                }
              }
              pick={
                shouldCollectAddress ? undefined : ['firstName', 'lastName']
              }
            />

            <Input
              type="date"
              name={FormKeys.EstimatedPurchaseDate}
              required
              id="estimated-purchase-date"
              label="Estimated Purchase Date"
              defaultValue={draft?.estimatedPurchaseDate}
            />

            <Select
              id="purchase-location"
              label="Original Purchase Location"
              name={FormKeys.StoreSalesChannel}
              required
              defaultValue={
                draft?.storeSalesChannel ?
                  `${draft.storeSalesChannel.id}`
                : undefined
              }
            >
              {storeSalesChannels.map((option) => (
                <SelectItem key={option.id} value={`${option.id}`}>
                  {option.name}
                </SelectItem>
              ))}
            </Select>

            <Input
              id="orderNumber"
              className="sm:col-span-2"
              label=" Original Order Number"
              name={FormKeys.OrderNumber}
              defaultValue={draft?.orderNumber}
            />

            {customFields.map((field) => (
              <CustomField
                key={field.id}
                className="sm:col-span-2"
                field={field}
                defaultValue={draft?.customFields[field.id]?.value}
              />
            ))}

            <Field.Root className="sm:col-span-2">
              <Field.Label>
                Proof of Purchase{' '}
                {shouldCollectProofOfPurchase && <span aria-hidden>*</span>}
              </Field.Label>
              <FileUploader
                name={FormKeys.Assets}
                requiredCount={requiredMediaCount}
                description="Upload a receipt, invoice, or other proof of purchase."
                value={draft?.proofOfPurchaseAssets ?? []}
              />
              {showFieldHelpText && (
                <Field.HelpText error>
                  {actionResult.form?.assets}
                </Field.HelpText>
              )}
              <input
                type="hidden"
                data-testid="required-asset-count"
                name={FormKeys.RequiredAssetCount}
                value={requiredMediaCount}
              />
              <input
                type="hidden"
                name={FormKeys.Referrer}
                value={referrer}
                data-testid="referrer"
              />
            </Field.Root>
          </div>

          {showAlert && (
            <Alert variant="error" title={actionResult.message ?? ''}>
              <p>{actionResult.form?.assets}</p>
            </Alert>
          )}

          <Submit loading={isLoading}>Next</Submit>
        </Form>
      </Card>
    </Page>
  );
}
