import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { filesFormSchema } from '~/utils/file';
import { logError } from '~/utils/observability';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { mediaAssetSchema, stringToJSONSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

const schema = zfd.formData(
  z
    .object({
      noteFromCustomer: zfd.text(z.string().optional()),
      assets: zfd.repeatableOfType(stringToJSONSchema.pipe(mediaAssetSchema)),
      requiredImgUploadCount: zfd.numeric(),
    })
    .merge(filesFormSchema),
);

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  const { storefrontId } = context.order;
  const results = schema.safeParse(formData);

  if (!results.success) {
    const formInfo = Object.fromEntries([
      ...formData.entries(),
      [
        'files',
        formData.getAll('files').map((file) => {
          if (file instanceof File) {
            return {
              name: file.name,
              size: file.size,
            };
          }
          return file;
        }),
      ],
    ]) as Record<string, unknown>;

    const instanceOfFileErr = results.error.issues
      .map((issue) => issue.message)
      .includes('Input not instance of File');

    if (instanceOfFileErr) {
      logError('Not instance of File', {
        extra: {
          error: results.error,
          formInfo,
        },
      });
      return json<ActionResult>({
        ok: false,
        message: 'Please try a different file.',
      });
    }
    return error(
      new Error('Malformed form data', {
        cause: {
          error: results.error,
          formInfo,
        },
      }),
    );
  }

  const { noteFromCustomer, assets, files, requiredImgUploadCount } =
    results.data;
  const existingMediaCount = assets.length + files.length;
  const assetsRequired = requiredImgUploadCount > 0;

  if (assetsRequired && existingMediaCount < requiredImgUploadCount) {
    return json<ActionResult>({
      ok: false,
      message: `A minimum of ${requiredImgUploadCount} media files are required`,
    });
  }

  const uploads =
    files.length ?
      api.uploadFiles({
        params: { storefrontId },
        query: { destination: 'gspClaimImg' },
        body: files,
      })
    : Promise.resolve({ assets: [], errors: [] });

  return uploads.then(({ assets: newAssets, errors }) => {
    context.setMeta({
      noteFromCustomer,
      assets: [...assets, ...newAssets],
    });

    return errors.length ?
        json<ActionResult>({
          ok: false,
          message: `The following failed to upload: ${errors.join(', ')}.`,
        })
      : redirect('../../address');
  });
});

export const useMetaActionResult = createActionResultHook<ActionResult>();
