import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { Address } from '~/utils/types';

export type AddressData = {
  address: Partial<Address>;
};

export default crewLoader(({ context, params: { store } }) => {
  if (!context.order) {
    return redirect(`/${store}`);
  }

  if (!context.lineItemClaims.length) {
    return redirect(`/${store}/order/${context.order.idFromPlatform}`);
  }

  const address = context.address ?? context.order.shippingAddress;

  return address ?
      json<AddressData>({
        address,
      })
    : redirect('./edit');
});

export const useAddressData = createLoaderDataHook<AddressData>();
