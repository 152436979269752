import { GspReasonLabels } from '~/utils/constants';
import { toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { claimAddressSchema } from '~/utils/schemas';
import { Address, GspState, Simplify } from '~/utils/types';

type Item = NonNullable<GspState['order']>['lineItems'][number];
export type LoaderData = {
  address: Address;
  items: Simplify<Item & { unitDisplayPrice: string }>[];
  reason: string;
  meta: GspState['meta'];
};

export default gspLoader(({ context, params: { store = '' } }) => {
  if (!context.order) {
    return redirect(`/${store}`);
  }

  const { idFromPlatform, shippingAddress, currencyCode } = context.order;

  if (!context.reason) {
    return redirect(`../${idFromPlatform}/new`);
  }

  if (!context.itemIds?.length) {
    return redirect(`../${idFromPlatform}/new/${context.reason}/items`);
  }

  if (!context.meta) {
    return redirect(`../${idFromPlatform}/new/${context.reason}/meta`);
  }

  const validAddress = claimAddressSchema.safeParse(shippingAddress);

  if (!validAddress.success) {
    return redirect(`../${context.reason}/new/address/edit`);
  }
  const formatPrice = toCurrency(currencyCode);

  const items = context.order.lineItems
    .filter((item) => context.itemIds?.includes(item.id))
    .map((item) => ({
      ...item,
      unitDisplayPrice: formatPrice(item.unitPrice),
    }));

  return json<LoaderData>({
    address: validAddress.data,
    items,
    reason: GspReasonLabels[context.reason],
    meta: context.meta,
  });
});

export const useReviewData = createLoaderDataHook<LoaderData>();
