import api from '~/utils/api';
import {
  createLoaderDataHook,
  json,
  redirect,
  registrationLoader,
} from '~/utils/routing';
import { AsyncReturnType } from '~/utils/types';

export type ProductRegisterLoaderData = {
  identifyProductUrl?: string;
  identifyProductDetailText: string;
  identifyProductFormPlaceholder: string;
  identifyProductUrlButtonText: string;
  products: AsyncReturnType<typeof api.fetchRegistrationProducts>;
};

export const SEARCH_PARAM = 'q';

export default registrationLoader(({ context, request }) => {
  const search = new URLSearchParams(new URL(request.url).search);
  const searchTerm = search.get(SEARCH_PARAM);

  const {
    draftRegistration: draft,
    settings: {
      theme: {
        identifyProductDetailText,
        identifyProductUrl,
        identifyProductFormPlaceholder,
        identifyProductUrlButtonText,
      },
    },
  } = context;

  // if there is no draft registration, redirect to the lookup page
  if (!draft) return redirect(`..`);

  const productsRequest =
    searchTerm ?
      api.fetchRegistrationProducts({
        params: {
          storefrontId: context.settings.storefrontId,
        },
        query: {
          searchTerm,
          storeSalesChannelName: draft.storeSalesChannel.name,
          estimatedPurchaseDate: draft.estimatedPurchaseDate,
        },
      })
    : Promise.resolve([]);

  return productsRequest.then((products) =>
    json<ProductRegisterLoaderData>({
      products,
      identifyProductUrl,
      identifyProductFormPlaceholder:
        identifyProductFormPlaceholder ?? 'Search for your product',
      identifyProductUrlButtonText:
        identifyProductUrlButtonText ?? 'Identify Your Product',
      identifyProductDetailText:
        identifyProductDetailText ??
        'If you need help identifying your product, please contact us.',
    }),
  );
});

export const useRegisterProductData =
  createLoaderDataHook<ProductRegisterLoaderData>();
