import { RouteObject } from 'react-router-dom';
import { catchallRoute } from '~/utils/routing';
import Claim from './claim';
import action from './crew-lookup-action';
import Component from './crew-lookup-component';
import loader from './crew-lookup-loader';
import InactiveMerchant from './inactive';
import Order from './order';
import Register from './register';

export default {
  path: '/:store',
  children: [
    {
      id: 'crewLookup',
      index: true,
      action,
      loader,
      Component,
    },
    Order,
    Claim,
    InactiveMerchant,
    Register,
    catchallRoute,
  ],
} satisfies RouteObject;
