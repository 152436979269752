import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import Items from './items';
import Meta from './meta';

export default {
  path: ':reason',
  loader: nonRenderingRouteLoader({
    path: ':store?/reorder/:idFromPlatform/new/:reason/:step',
    end: false,
  }),
  children: [Items, Meta],
} satisfies RouteObject;
