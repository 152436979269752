import { RouteObject } from 'react-router-dom';
import DraftClaim from './[:draftId]';
import action from './order-claim-action';
import Component from './order-claim-component';
import loader from './order-claim-loader';

export default {
  id: 'crewClaim',
  path: ':claimType',
  children: [
    {
      index: true,
      loader,
      Component,
      action,
    },
    DraftClaim,
  ],
} satisfies RouteObject;
