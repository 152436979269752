import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
} from '~/utils/routing';
import { Address, OrderAddress } from '~/utils/types';

export type AddressData = {
  address: Address | OrderAddress | null;
};

export default crewLoader(({ context, params: { store } }) =>
  context.order ?
    json<AddressData>({
      address: context.address ?? context.order.shippingAddress,
    })
  : redirect(`/${store}`),
);

export const useAddressData = createLoaderDataHook<AddressData>();
