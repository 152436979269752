import { useState, type MouseEventHandler } from 'react';

const usePagination = <T>(pages: T[], startingPage = 1) => {
  const [currentPage, setCurrentPage] = useState(startingPage);

  const next: MouseEventHandler = () =>
    setCurrentPage((prev) => (prev === pages.length ? 1 : prev + 1));

  const previous: MouseEventHandler = () =>
    setCurrentPage((prev) => (prev === 1 ? pages.length : prev - 1));

  return {
    pageCount: pages.length,
    currentPage,
    data: pages[currentPage - 1],
    next,
    previous,
  };
};

export default usePagination;
