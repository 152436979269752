import { z } from 'zod';
import { zfd, json as zfdJsonParse } from 'zod-form-data';
import api from '~/utils/api';
import { filesFormSchema } from '~/utils/file';
import { logError } from '~/utils/observability';
import {
  createActionResultHook,
  error,
  json,
  redirect,
  registrationAction,
} from '~/utils/routing';
import {
  addressSchema,
  draftRegistrationSchema,
  mediaAssetSchema,
} from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

export enum FormKeys {
  Email = 'email',
  StoreSalesChannel = 'storeSalesChannel',
  EstimatedPurchaseDate = 'estimatedPurchaseDate',
  Assets = 'assets',
  RequiredAssetCount = 'requiredAssetCount',
  OrderNumber = 'orderNumber',
  Referrer = 'referrer',
}

const baseSchema = z
  .object({
    [FormKeys.Email]: zfd.text(z.string()),
    [FormKeys.StoreSalesChannel]: zfd.numeric(),
    [FormKeys.EstimatedPurchaseDate]: zfd.text(z.string()),
    [FormKeys.OrderNumber]: zfd.text(z.string().optional()),
    [FormKeys.RequiredAssetCount]: zfd.numeric(z.number().int().nonnegative()),
    [FormKeys.Referrer]: zfd.text(z.string().optional()),
    [FormKeys.Assets]: zfd.repeatableOfType(zfdJsonParse(mediaAssetSchema)),
  })
  .merge(filesFormSchema)
  // for custom fields
  .catchall(draftRegistrationSchema.shape.customFields.valueSchema);

const schema = zfd.formData(
  z.discriminatedUnion('shouldCollectAddress', [
    baseSchema.extend({
      shouldCollectAddress: z.literal('true'),
      address: addressSchema,
    }),
    baseSchema.extend({
      shouldCollectAddress: z.literal('false'),
      address: addressSchema.pick({ firstName: true, lastName: true }),
    }),
  ]),
);

export default registrationAction(({ formData, context }) => {
  const { storefrontId, registrations } = context.settings;

  formData.set('shouldCollectAddress', `${registrations.shouldCollectAddress}`);

  const results = schema.safeParse(formData);

  if (!results.success) {
    const formInfo = Object.fromEntries([
      ...formData.entries(),
      [
        'files',
        formData.getAll('files').map((file) => {
          if (file instanceof File) {
            return {
              name: file.name,
              size: file.size,
            };
          }
          return file;
        }),
      ],
    ]) as Record<string, unknown>;

    const instanceOfFileErr = results.error.issues
      .map((issue) => issue.message)
      .includes('Input not instance of File');

    if (instanceOfFileErr) {
      logError('Not instance of File', {
        extra: {
          error: results.error,
          formInfo,
        },
      });
      return json<ActionResult<FormKeys>>({
        ok: false,
        form: {
          [FormKeys.Assets]: 'Please try a different file.',
        },
      });
    }

    return error(
      new Error('Malformed form data', {
        cause: {
          error: results.error,
          formInfo,
        },
      }),
    );
  }

  const {
    [FormKeys.Email]: email,
    [FormKeys.StoreSalesChannel]: storeSalesChannelId,
    [FormKeys.EstimatedPurchaseDate]: estimatedPurchaseDate,
    [FormKeys.OrderNumber]: orderNumber,
    [FormKeys.RequiredAssetCount]: requiredAssetCount,
    [FormKeys.Assets]: assets = [],
    [FormKeys.Referrer]: referrer,
    files,
    address,
    shouldCollectAddress,
    ...customFields
  } = results.data;

  const storeSalesChannel = registrations.storeSalesChannels.find(
    (channel) => channel.id === storeSalesChannelId,
  );

  if (!storeSalesChannel) {
    return error(new Error('Invalid store sales channel'));
  }

  const assetsRequired = requiredAssetCount > 0;
  const existingMediaCount = assets.length + files.length;

  if (assetsRequired && existingMediaCount < requiredAssetCount) {
    return json<ActionResult<FormKeys>>({
      ok: false,
      form: {
        [FormKeys.Assets]: `A minimum of ${requiredAssetCount} files are required`,
      },
    });
  }

  const uploads =
    files.length ?
      api.uploadFiles({
        params: { storefrontId },
        query: { destination: 'crewProofOfPurchase' },
        body: files,
      })
    : Promise.resolve({ assets: [], errors: [] });

  return uploads.then(({ assets: newAssets, errors }) => {
    context.setDraftRegistration({
      email,
      firstName: address.firstName,
      lastName: address.lastName,
      storeSalesChannel,
      estimatedPurchaseDate,
      address: shouldCollectAddress === 'true' ? address : null,
      referrer,
      customFields,
      proofOfPurchaseAssets: [...assets, ...newAssets],
      orderNumber,
    });

    return errors.length ?
        json<ActionResult<FormKeys>>({
          ok: false,
          message: 'Problem uploading files',
          form: {
            [FormKeys.Assets]: `The following failed to upload: ${errors.join(', ')}.`,
          },
        })
      : redirect('./product');
  });
});

export const useRegisterActionResult =
  createActionResultHook<ActionResult<FormKeys>>();
