import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import api from '~/utils/api';
import { filesFormSchema } from '~/utils/file';
import { logError } from '~/utils/observability';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import {
  draftLineItemClaimSchema,
  mediaAssetSchema,
  stringToJSONSchema,
} from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

export enum FormKeys {
  Comments = 'comments',
  Assets = 'assets',
  RequiredAssetCount = 'requiredAssetCount',
}

const schema = zfd.formData(
  z
    .object({
      [FormKeys.Comments]: zfd
        .text(z.string().optional())
        .transform((value) => value ?? null),
      [FormKeys.RequiredAssetCount]: zfd.numeric(
        z.number().int().nonnegative(),
      ),
      [FormKeys.Assets]: zfd.repeatableOfType(
        stringToJSONSchema.pipe(mediaAssetSchema),
      ),
    })
    .merge(filesFormSchema)
    .catchall(draftLineItemClaimSchema.shape.customFields.valueSchema),
);

export default crewAction(
  withDraftClaim(({ formData, claim, context }) => {
    const { storefrontId } = context.settings;
    const results = schema.safeParse(formData);

    if (!results.success) {
      const formInfo = Object.fromEntries([
        ...formData.entries(),
        [
          'files',
          formData.getAll('files').map((file) => {
            if (file instanceof File) {
              return {
                name: file.name,
                size: file.size,
              };
            }
            return file;
          }),
        ],
      ]) as Record<string, unknown>;

      const instanceOfFileErr = results.error.issues
        .map((issue) => issue.message)
        .includes('Input not instance of File');

      if (instanceOfFileErr) {
        logError('Not instance of File', {
          extra: {
            error: results.error,
            formInfo,
          },
        });
        return json<ActionResult<FormKeys>>({
          ok: false,
          form: {
            [FormKeys.Assets]: 'Please try a different file.',
          },
        });
      }

      return error(
        new Error('Malformed form data', {
          cause: {
            error: results.error,
            formInfo,
          },
        }),
      );
    }

    const {
      [FormKeys.Comments]: comments,
      [FormKeys.Assets]: assets,
      files,
      [FormKeys.RequiredAssetCount]: requiredAssetCount,
      ...customFields
    } = results.data;

    const existingMediaCount = assets.length + files.length;
    const assetsRequired = requiredAssetCount > 0;

    if (assetsRequired && existingMediaCount < requiredAssetCount) {
      return json<ActionResult<FormKeys>>({
        ok: false,
        form: {
          [FormKeys.Assets]: `A minimum of ${requiredAssetCount} media ${requiredAssetCount === 1 ? 'file is' : 'files are'} required.`,
        },
      });
    }

    const uploads =
      files.length ?
        api.uploadFiles({
          params: { storefrontId },
          query: { destination: 'crewClaimImg' },
          body: files,
        })
      : Promise.resolve({ assets: [], errors: [] });

    return uploads.then(({ assets: newAssets, errors }) => {
      context.setDraftClaim({
        ...claim,
        customFields,
        comments,
        assets: [...assets, ...newAssets],
      });

      return errors.length ?
          json<ActionResult<FormKeys>>({
            ok: false,
            message: 'Problem uploading files',
            form: {
              [FormKeys.Assets]: `The following failed to upload: ${errors.join(', ')}.`,
            },
          })
        : redirect('../resolution', StatusCodes.SEE_OTHER);
    });
  }),
);

export const useMetaActionResult =
  createActionResultHook<ActionResult<FormKeys>>();
