import { RouteObject } from 'react-router-dom';
import action from './refund-options-action';
import Component from './refund-options-component';
import ErrorBoundary from './refund-options-error-boundary';
import loader from './refund-options-loader';

export default {
  id: 'refundOptions',
  path: 'refund-options',
  action,
  loader,
  Component,
  ErrorBoundary,
} satisfies RouteObject;
