import { ReactNode, useId } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children?: ReactNode;
  className?: string;
  headline?: string;
  title?: string | ReactNode;
  headerContent?: ReactNode;
  showHeadline?: boolean;
  testId?: string;
};
export default function Card({
  children,
  className,
  headline,
  title,
  headerContent,
  showHeadline = true,
  testId,
}: Props) {
  const headlineId = useId();

  return (
    <section
      data-testid={testId}
      aria-labelledby={headline ? headlineId : ''}
      className={twMerge(
        'flex flex-col gap-6 rounded-md border border-gray-200 bg-white p-4 shadow-sm md:p-8',
        className,
      )}
    >
      {(headline || title || headerContent) && (
        <header className={showHeadline ? undefined : 'sr-only'}>
          {headline && (
            <div
              className={twMerge(
                headerContent && 'grid gap-2 sm:grid-cols-[max-content_1fr]',
              )}
            >
              <h2
                id={headlineId}
                className={twMerge(
                  'text-left text-xl font-medium text-gray-800',
                  headerContent && 'order-last sm:order-first',
                )}
              >
                {headline}
              </h2>
              {headerContent}
            </div>
          )}
          {title && <p className="text-gray-500">{title}</p>}
        </header>
      )}
      {children}
    </section>
  );
}
