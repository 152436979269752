import { RouteObject } from 'react-router-dom';
import Component from './shipping-protection-component';
import loader from './shipping-protection-loader';

export default {
  id: 'shippingProtection',
  path: 'shipping-protection',
  Component,
  loader,
} satisfies RouteObject;
