import Card from '~/components/card';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { useShippingIssueLoaderData } from './shipping-issue-loader';

export default function ShippingIssueComponent() {
  const { shippingClaimAction } = useShippingIssueLoaderData();
  const { description, headline, href, buttonText } = shippingClaimAction;

  return (
    <Page showHeadline={false} headline="">
      <Card headline={headline}>
        {description}
        <LinkButton
          variant="filled"
          to={href}
          reloadDocument
          target="_blank"
          rel="noreferrer"
          className="w-full md:w-1/2"
        >
          {buttonText}
        </LinkButton>
      </Card>
    </Page>
  );
}
