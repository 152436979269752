import { forwardRef, useId, useMemo, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { formatProductOptions, shouldShowPrice } from '~/utils/formatters';
import ProductImage from './product-image';

type Props = {
  name: string;
  options?: { value: string }[];
  imgSrc?: string | null;
  price?: string;
  footnote?: ReactNode;
  quantity?: number;
  remainingReturnableQuantity?: number;
  variant?: 'regular' | 'small';
  children?: ReactNode;
  className?: string;
};

const LineItem = forwardRef<HTMLElement, Props>(
  (
    {
      name,
      imgSrc,
      price,
      quantity,
      remainingReturnableQuantity,
      footnote,
      children,
      className,
      options = [],
      variant = 'regular',
    },
    ref,
  ) => {
    const labelId = useId();

    const description = useMemo(() => formatProductOptions(options), [options]);

    const showPrice = shouldShowPrice(price);

    return (
      <article
        className={twMerge(
          'flex flex-col gap-8 sm:flex-row sm:items-center',
          className,
        )}
        aria-labelledby={labelId}
        ref={ref}
      >
        <div
          className={twMerge(
            'flex w-full items-center gap-4',
            children && 'sm:basis-2/3',
          )}
        >
          <ProductImage
            src={imgSrc}
            name={name}
            quantity={quantity}
            remainingReturnableQuantity={remainingReturnableQuantity}
            variant={variant}
          />
          <div className="flex w-full flex-col gap-1 text-sm font-medium text-gray-500">
            <div className="flex w-full justify-between text-gray-800">
              <span id={labelId}>{name}</span>
              {variant === 'small' && showPrice && (
                <span className="hidden sm:block">{price}</span>
              )}
            </div>
            <span className="font-normal">{description}</span>
            {variant === 'regular' && showPrice && <span>{price}</span>}
            {footnote}
          </div>
        </div>
        {children && <div className="sm:basis-1/3">{children}</div>}
      </article>
    );
  },
);

export default LineItem;
