import api from '~/utils/api';
import { error, gspAction, redirect } from '~/utils/routing';
import { claimAddressSchema } from '~/utils/schemas';

export default gspAction(async ({ context }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  if (!context.reason) {
    return error(new Error('Context Error: Reason not found'));
  }

  if (!context.itemIds?.length) {
    return error(new Error('Context Error: No Item IDs'));
  }

  const address = claimAddressSchema.safeParse(context.order.shippingAddress);

  if (!address.success) {
    return error(
      new Error('Context Error: Invalid address', {
        cause: {
          error: address.error,
          entries:
            context.order.shippingAddress ?
              Array.from(Object.entries(context.order.shippingAddress))
            : null,
        },
      }),
    );
  }

  const claim = await api.createProtectionClaim({
    params: { storefrontId: context.order.storefrontId },
    body: {
      reason: context.reason,
      originalStoreOrder: {
        id: context.order.id,
        lineItems: context.order.lineItems
          .filter((item) => context.itemIds?.includes(item.id))
          .map(({ id, quantity }) => ({
            id,
            quantity,
          })),
      },
      noteFromCustomer: context.meta?.noteFromCustomer,
      images: context.meta?.assets?.map(({ name }) => name) ?? [],
      shippingAddress: address.data,
    },
  });

  // cache the claim with the order
  context.order.shippingClaims = [
    ...(context.order.shippingClaims ?? []),
    claim,
  ];
  // clear the rest of the context
  context.clearMeta();
  context.clearReason();
  context.clearItemIds();

  return redirect(`../../claim/${claim.id}/new`);
});
