import { matchPath } from 'react-router-dom';
import {
  createLoaderDataHook,
  crewLoader,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import type { CrewContext } from '~/utils/types';

export type DraftClaimLoaderData = {
  claim: NonNullable<CrewContext['draftClaim']>;
};
export default crewLoader(
  withDraftClaim(({ claim, request }) => {
    const pathMatch = matchPath(
      '/:store/order/:idFromPlatform/:claimType/:draftId/:step/*',
      new URL(request.url).pathname,
    );

    return pathMatch ?
        json<DraftClaimLoaderData>({ claim })
      : redirect('quantity');
  }),
);

export const useClaimLoaderData = createLoaderDataHook<DraftClaimLoaderData>();
