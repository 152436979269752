import type { RouteObject } from 'react-router-dom';
import action from './warranty-review-action';
import Component from './warranty-review-component';
import loader from './warranty-review-loader';

export default {
  id: 'reviewWarranty',
  path: 'warranty',
  Component,
  loader,
  action,
} satisfies RouteObject;
