import { RouteObject } from 'react-router-dom';
import action from './quantity-action';
import Component from './quantity-component';
import loader from './quantity-loader';

export default {
  id: 'claimQuantity',
  path: 'quantity',
  action,
  loader,
  Component,
} satisfies RouteObject;
