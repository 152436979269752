import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { addressSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

const schema = zfd.formData({
  address: addressSchema,
});

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  const result = schema.safeParse(formData);

  if (!result.success) {
    return json<ActionResult>({
      ok: false,
      message: 'Invalid address. Check your address information.',
    });
  }

  context.order.shippingAddress = result.data.address;

  return redirect('../../review');
});

export const useEditAddressActionResult =
  createActionResultHook<ActionResult>();
