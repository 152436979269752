import { useLayoutEffect, useSyncExternalStore } from 'react';
import Payments from '~/stores/payments';
import Card from './card';
import DL, { DLItem } from './definition-list';
import Divider from './divider';

export default function PaymentSummary<Amount extends { display: string }>({
  amount,
  headline = 'Payment Summary',
  label,
}: {
  amount: Amount;
  headline?: string;
  label: string;
}) {
  const paymentUI = useSyncExternalStore(
    (listener) => Payments.subscribe(listener),
    () => Payments.getSnapshot(),
  );

  useLayoutEffect(() => paymentUI?.mount('#card-element'), [paymentUI]);

  return (
    <Card headline={headline}>
      <DL>
        <DLItem variant="lg" label={label} value={amount.display} />
      </DL>
      <Divider />
      <div id="card-element" className="w-full" />
    </Card>
  );
}
