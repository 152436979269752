import { RouteObject } from 'react-router-dom';
import action from './meta-action';
import Component from './meta-component';
import loader from './meta-loader';

export default {
  id: 'claimMeta',
  path: 'meta',
  Component,
  action,
  loader,
} satisfies RouteObject;
