import { RouteObject } from 'react-router-dom';
import action from './follow-up-action';
import Component from './follow-up-component';
import loader from './follow-up-loader';

export default {
  id: 'claimFollowUp',
  path: 'follow-up',
  Component,
  loader,
  action,
  shouldRevalidate: () => false,
} satisfies RouteObject;
