import { z } from 'zod';
import api from './api';
import { prop } from './compute';
import { ClaimType } from './constants';
import {
  claimLineItemCreateSchema,
  draftLineItemClaimSchema,
  giftCardClaimSchema,
  lineItemClaimSchema,
  refundClaimSchema,
  typeGuardFromSchema,
  variantExchangeClaimSchema,
} from './schemas';

type LineItemClaim = z.infer<typeof lineItemClaimSchema>;
type ClaimLineItemCreate = Parameters<
  typeof api.createCrewClaim
>[0]['body']['claimLineItems'][number];
type AutomationLineItem = Parameters<
  typeof api.checkBeforeClaimSubmission
>[0]['body']['lineItems'][number];

const extractVariantExchangeLineItem = ({
  quantity,
  variant,
}: z.infer<typeof variantExchangeClaimSchema>): Pick<
  ClaimLineItemCreate,
  'variantExchangeLineItem'
> => ({
  variantExchangeLineItem: {
    quantity,
    idFromPlatform: variant.idFromPlatform,
  },
});

const extractCustomFields = ({
  customFields,
}: LineItemClaim): ClaimLineItemCreate['customFields'] =>
  Object.entries(customFields).map(([id, field]) => ({
    id: Number(id),
    value:
      field.type === 'Date' ?
        new Date(`${field.value}T00:00:00`).toISOString()
      : field.value,
  }));

export const pendingClaimsToClaimLineItemCreate = (
  lineItemClaims: LineItemClaim[],
): ClaimLineItemCreate[] =>
  lineItemClaims.map((claim) =>
    claimLineItemCreateSchema.parse({
      ...claim,
      originalStoreOrderLineItemId: claim.lineItem.id,
      images: claim.assets?.map(prop('name')),
      customFields: extractCustomFields(claim),
      ...(claim.variant && extractVariantExchangeLineItem(claim)),
    }),
  );

export const pendingLineItemClaimToAutomationLineItem = (
  claim: LineItemClaim,
) =>
  ({
    claimType: claim.claimType,
    lineItemId: claim.lineItem.id,
    reasonId: claim.reason.id,
    reasonCategoryCode: claim.reason.category,
    reasonDetailId: claim.reason.detail?.id,
    // ! @deprecated - moving to back-end caring about quantity
    lineItemTotal: (claim.unitPrice + claim.unitTax) * claim.quantity,
    requestedResolutionMethod: claim.requestedResolutionMethodEnum,
    customFields: extractCustomFields(claim),
    quantity: claim.quantity,
    lineItemIdFromPlatform: claim.lineItem.idFromPlatform,
    variantExchangeIdFromPlatform: claim.variant?.idFromPlatform,
  }) satisfies AutomationLineItem;

export const getClaimReasons = (
  claim: z.infer<typeof draftLineItemClaimSchema>,
) =>
  claim.claimType === ClaimType.return ?
    claim.lineItem.return.claimReasons
  : claim.lineItem.warranty.claimReasons;

export const isLineItemInClaims = (
  linItemId: LineItemClaim['lineItem']['id'],
  claims: LineItemClaim[],
) => claims.some((claim) => claim.lineItem.id === linItemId);

export const isExchangeClaim = typeGuardFromSchema(variantExchangeClaimSchema);
export const isRefundClaim = typeGuardFromSchema(refundClaimSchema);
export const isGiftCardClaim = typeGuardFromSchema(giftCardClaimSchema);
export const isCreditClaim = typeGuardFromSchema(
  z.union([giftCardClaimSchema, refundClaimSchema]),
);
