import { GspClaimReason } from '~/utils/constants';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { GspState } from '~/utils/types';

export type LoaderData = {
  uploadImage: boolean;
  requiredImgUploadCount: number;
} & GspState['meta'];

export default gspLoader(({ params: { store = '' }, context }) => {
  if (!context.order || !context.reason) {
    return redirect(`/${store}`);
  }

  const requiredImgUploadCount =
    (
      context.reason === GspClaimReason.damaged &&
      context.order.isShippingClaimImgRequired
    ) ?
      1
    : 0;

  return json<LoaderData>({
    uploadImage: context.reason === GspClaimReason.damaged,
    requiredImgUploadCount,
    ...context.meta,
  });
});

export const useMetaLoaderData = createLoaderDataHook<LoaderData>();
