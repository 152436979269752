import { z } from 'zod';

const envSchema = z.object({
  VITE_NODE_ENV: z.enum(['prod', 'stage', 'dev']), // TODO use `NodeEnv` as `nativeEnum` from `corso-lib`
  VITE_CREW_API_URL: z.string(),
  VITE_CREW_CDN_URL: z.string(),
  VITE_SENTRY_DSN: z.string(),
  VITE_BUILD_SHA: z.string(),
  VITE_STRIPE_PUBLISHABLE_KEY: z.string(),
  VITE_MSW: z.string().optional(),
});

const env = envSchema.parse(import.meta.env);

export default { ...env, NOT_PROD: env.VITE_NODE_ENV !== 'prod' } as const;
