import { StatusCodes } from 'http-status-codes';
import { zfd } from 'zod-form-data';
import { ClaimType } from '~/utils/constants';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
} from '~/utils/routing';
import { addressSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

export default crewAction(({ formData, context }) => {
  const result = zfd.formData(addressSchema).safeParse(formData);

  if (!result.success) {
    return json<ActionResult>({
      ok: false,
      message: 'Invalid address. Please update your address.',
    });
  }

  context.setAddress(result.data);

  const isWarranty = context.lineItemClaims.some(
    (claim) => claim.claimType === ClaimType.warranty,
  );

  const nextPage = isWarranty ? `../review/warranty` : `../return-methods`;

  return redirect(nextPage, StatusCodes.SEE_OTHER);
});

export const useAddressActionResult = createActionResultHook<ActionResult>();
