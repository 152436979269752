import { StatusCodes } from 'http-status-codes';
import { zfd } from 'zod-form-data';
import { crewAction, json, redirect } from '~/utils/routing';
import { addressSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

const schema = zfd.formData({
  address: addressSchema,
});

export default crewAction(({ formData, context }) => {
  const result = schema.safeParse(formData);

  if (!result.success) {
    return json<ActionResult>({
      ok: false,
      message: 'Invalid address. Check your address information.',
    });
  }

  context.setAddress(result.data.address);

  return redirect(`..`, StatusCodes.SEE_OTHER);
});
