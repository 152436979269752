import { RouteObject } from 'react-router-dom';
import action from './register-product-action';
import Component from './register-product-component';
import loader from './register-product-loader';

export default {
  path: 'product',
  loader,
  Component,
  action,
} satisfies RouteObject;
