import api from './api';
import { AsyncReturnType } from './types';

type Theme = AsyncReturnType<typeof api.fetchSettings>['theme'];

const createLink = () => {
  const link = document.createElement('link');
  link.rel = 'icon';

  document.head.appendChild(link);

  return link;
};

const setStandardFavicon = () => {
  const standard =
    document.querySelector<HTMLLinkElement>("link[rel~='icon']") ??
    createLink();
  standard.type = 'image/svg+xml';
  standard.href = '/corso-favicon.svg';
  const dark = createLink();
  dark.type = 'image/svg+xml';
  dark.href = '/corso-favicon-dark.svg';
  dark.media = '(prefers-color-scheme: dark)';
};

const setFavicon = (url: string) => {
  if (url === '') {
    setStandardFavicon();
  } else {
    const link =
      document.querySelector<HTMLLinkElement>("link[rel~='icon']") ??
      createLink();

    link.href = url;
  }
};

const setTitle = (title = 'Returns by Corso') => {
  document.title = title;
};

export const defaultTheme: Theme = {
  merchantPrimaryColor: '#000000',
  merchantLogoUrl: 'https://cdn.corso.com/img%2Fcrew-ui-corso-logo.svg',
  merchantBackgroundColor: '#FFFFFF',
  merchantPageTitle: '',
  merchantBackgroundUrl: null,
  merchantFaviconUrl: '',
  footerText: '',
};

export const setTheme = (theme: Theme) => {
  const color = theme.merchantPrimaryColor ?? defaultTheme.merchantPrimaryColor;
  const bgColor =
    theme.merchantBackgroundColor ?? defaultTheme.merchantBackgroundColor;
  const logoUrl = theme.merchantLogoUrl ?? defaultTheme.merchantLogoUrl;
  const variables = `:root {
    --corso-primary: ${color};
    --corso-surface: ${color}05;
    --corso-primary-on-container: ${color};
    --corso-on-primary:#ffffff;
    --corso-banner:  ${bgColor};
    --corso-header-img-url: url('${logoUrl}');
    --corso-banner-img-url: url('${theme.merchantBackgroundUrl ?? ''}');
  }`;

  const style = document.createElement('style');
  style.appendChild(document.createTextNode(variables));
  document.head.appendChild(style);

  setFavicon(theme.merchantFaviconUrl);
  setTitle(theme.merchantPageTitle);
};
