import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import Return from './return';
import Warranty from './warranty';

export default {
  path: 'review',
  loader: nonRenderingRouteLoader({
    path: '/:store/order/:idFromPlatform/review',
    end: false,
  }),
  children: [Return, Warranty],
} satisfies RouteObject;
