import { GspContext, GspState } from '~/utils/types';

const STORAGE_KEY = 'corso-gsp-state';

let state: GspState = {
  order: null,
  reason: null,
  itemIds: null,
  meta: null,
};

try {
  const storedState = sessionStorage.getItem(STORAGE_KEY);

  if (storedState) {
    state = JSON.parse(storedState) as GspState;
  }
} catch {
  state = {
    order: null,
    reason: null,
    itemIds: null,
    meta: null,
  };
}

const notifyChange = (newState: GspState) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newState));
};

const assign = <K extends keyof GspState, V extends GspState[K]>(
  key: K,
  value: V,
) => {
  state[key] = value;

  notifyChange(state);

  return value;
};

export const GspStore: GspContext = {
  get order() {
    return state.order;
  },
  get reason() {
    return state.reason;
  },
  get itemIds() {
    return state.itemIds;
  },
  get meta() {
    return state.meta;
  },

  setOrder: (order) => assign('order', order),
  setReason: (reason) => assign('reason', reason),
  setItemIds: (ids) => assign('itemIds', ids),
  setMeta: (meta) => assign('meta', meta),
  clearOrder: () => assign('order', null),
  clearReason: () => assign('reason', null),
  clearItemIds: () => assign('itemIds', null),
  clearMeta: () => assign('meta', null),

  reset: () => {
    state = {
      order: null,
      reason: null,
      itemIds: null,
      meta: null,
    };

    notifyChange(state);
  },
};
