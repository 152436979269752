import { z } from 'zod';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { claimAddressSchema } from '~/utils/schemas';

type LoaderData = {
  address: z.infer<typeof claimAddressSchema>;
};

export default gspLoader(({ params: { store = '' }, context: { order } }) => {
  if (!order) {
    return redirect(`/${store}`);
  }

  const result = claimAddressSchema.safeParse(order.shippingAddress);

  if (!result.success) {
    return redirect(`edit`);
  }

  return json<LoaderData>({ address: result.data });
});

export const useAddressData = createLoaderDataHook<LoaderData>();
