import Card from '~/components/card';
import DL, { DLItem } from '~/components/definition-list';
import LinkButton from '~/components/link-button';
import Page from '~/components/page';
import { useOrderOverviewLoaderData } from './order-overview-loader';

export default function OrderOverview() {
  const data = useOrderOverviewLoaderData();

  const title =
    data.wasShippingProtected ?
      `Here's your order information`
    : `We found your order but looks like you didn't purchase shipping protection from Corso. Please continue to submit a shipping support request`;

  return (
    <Page headline="Found It." title={title}>
      <Card>
        <DL divide className="[&>dd]:text-gray-700">
          <DLItem label="Store" value={data.store} />
          <DLItem label="Order Number" value={data.orderNo} />
          <DLItem label="Order Date" value={data.orderDate} />
          <DLItem label="Price" value={data.price} />
          {data.postalCode && (
            <DLItem label="Postal Code" value={data.postalCode} />
          )}
        </DL>

        <LinkButton variant="filled" className="w-full md:w-1/2" to="new">
          Get Started
        </LinkButton>
      </Card>
    </Page>
  );
}
