import { StatusCodes } from 'http-status-codes';
import { zfd } from 'zod-form-data';
import { ClaimType } from '~/utils/constants';
import { crewAction, json, redirect } from '~/utils/routing';
import { shipmentMethodSchema } from '~/utils/schemas';
import { ActionErrorResult } from '~/utils/types';

export const FIELD_KEY = 'return-method';

const formDataSchema = zfd.formData({
  [FIELD_KEY]: zfd.json(shipmentMethodSchema),
});

export default crewAction(({ formData, context }) => {
  const result = formDataSchema.safeParse(formData);

  if (!result.success) {
    return json<ActionErrorResult>({
      ok: false,
      message: 'Invalid value for return method',
    });
  }

  const method = result.data[FIELD_KEY];

  context.setReturnMethod(method);

  const reviewPage =
    (
      context.lineItemClaims.some(
        (claim) => claim.claimType === ClaimType.warranty,
      )
    ) ?
      `../review/warranty`
    : `../review/return`;

  return redirect(reviewPage, StatusCodes.SEE_OTHER);
});
