import { RouteObject } from 'react-router-dom';
import action from './review-action';
import Component from './review-component';
import loader from './review-loader';

export default {
  path: 'review',
  Component,
  loader,
  action,
  shouldRevalidate: () => false, // don't want to run loader again with action submit since we are creating a claim and clearing the context
} satisfies RouteObject;
