import { RouteObject } from 'react-router-dom';
import action from './items-action';
import Component from './items-component';
import loader from './items-loader';

export default {
  path: 'items',
  Component,
  loader,
  action,
} satisfies RouteObject;
