type Predicate<P extends unknown[]> = (...args: P) => boolean;
export const not =
  <P extends unknown[]>(fn: Predicate<P>) =>
  (...args: Parameters<typeof fn>): boolean =>
    !fn(...args);
const sum = (numbers: (number | undefined)[]) =>
  numbers.reduce<number>((acc, num) => acc + (num ?? 0), 0);
export const sumBy =
  <T>(fn: (item: T) => number | undefined) =>
  (items: T[]) =>
    sum(items.map(fn));

export const prop =
  <T, K extends keyof T>(key: K) =>
  (obj: T) =>
    obj[key];

export const computeCost = ({
  unitPrice,
  quantity,
}: {
  unitPrice: number;
  quantity: number;
}) => unitPrice * quantity;

const computeTax = ({
  unitTax,
  quantity,
}: {
  unitTax: number;
  quantity: number;
}) => unitTax * quantity;

export const sumPrice = sumBy(computeCost);
export const sumTax = sumBy(computeTax);
