import { RouteObject } from 'react-router-dom';
import action from './reason-action';
import Component from './reason-component';
import loader from './reason-loader';

export default {
  id: 'claimReason',
  path: 'reason',
  Component,
  action,
  loader,
} satisfies RouteObject;
