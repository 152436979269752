import { StatusCodes } from 'http-status-codes';
import {
  PathPattern,
  RouteObject,
  LoaderFunctionArgs as RouterDataFunctionArgs,
  Params as RouterParams,
  isRouteErrorResponse,
  json,
  matchPath,
  redirect,
  useActionData,
  useLoaderData,
  useMatch,
  useNavigation,
  useNavigationType,
  useParams as useRouterParams,
  useRouteError as useRouterRouteError,
} from 'react-router-dom';
import { z } from 'zod';
import { CrewStore } from '~/stores/crew';
import { GspStore } from '~/stores/gsp';
import { RegistrationStore } from '~/stores/registrations';
import { logError } from './observability';
import {
  CrewContext,
  GspContext,
  RegistrationContext,
  Simplify,
} from './types';

type DataFunctionArgs<Context = unknown, Params = RouterParams> = Simplify<
  Omit<RouterDataFunctionArgs, 'context' | 'params'> & {
    context: Context;
    params: RouterDataFunctionArgs['params'] & Params;
  }
>;
type DataFunctionReturn = Response | Promise<Response>;
type DataFunction<
  TArgs extends DataFunctionArgs,
  TReturn extends DataFunctionReturn,
> = (args: TArgs) => TReturn;

type LoaderFunction<Context, Params = RouterParams> = DataFunction<
  DataFunctionArgs<Context, Params>,
  DataFunctionReturn
>;

type ActionFunction<Context, Params = RouterParams> = DataFunction<
  DataFunctionArgs<Context, Params> & { formData: FormData },
  DataFunctionReturn
>;

export const createLoaderDataHook =
  <T extends object>() =>
  () =>
    useLoaderData() as T;

export const createActionResultHook =
  <T extends object>() =>
  () =>
    useActionData() as T | undefined;

export const useIsLoading = () => {
  const navigation = useNavigation();
  const navigationType = useNavigationType();

  return (
    navigation.state === 'submitting' ||
    (navigation.state === 'loading' && navigationType !== 'POP')
  );
};

// ? normalize as error or response error???
export const useRouteError = () => {
  const error = useRouterRouteError();
  if (error instanceof Error) {
    return error;
  }

  if (isRouteErrorResponse(error)) {
    return new Error('Response Error', { cause: { originalError: error } });
  }

  return new Error('Unknown error occurred', {
    cause: { originalError: error },
  });
};

export const useParams = <Params = RouterParams>(schema?: z.Schema<Params>) => {
  const params = useRouterParams();

  return schema ? schema.parse(params) : params;
};

export { json, redirect };

// ? reject or response 400
export const error = (err: Error) => {
  logError(err, err.cause ? { extra: { cause: err.cause } } : undefined);

  return Promise.reject(err);
};

// TODO - delete these when everything is updated to use the error function
export const contextErrorStatus = {
  status: StatusCodes.NOT_FOUND,
  statusText: 'Context Not Found',
};

export const missingParamsErrorStatus = {
  status: StatusCodes.BAD_REQUEST,
  statusText: 'Missing Parameters',
};

export const pageLoadingErrorStatus = {
  status: StatusCodes.INTERNAL_SERVER_ERROR,
  statusText: 'Page Loading Error',
};

export const inactiveMerchantErrorStatus = {
  status: StatusCodes.FORBIDDEN,
  statusText: 'Inactive Merchant',
};

export const crewLoader =
  (fn: LoaderFunction<CrewContext, { store: string }>) =>
  ({ params, ...args }: RouterDataFunctionArgs) => {
    const { store, ...restParams } = params;

    if (!store) {
      return error(
        new Error('Missing store identifier', { cause: { params } }),
      );
    }

    // since all loaders run in parallel, we need to ensure the store is initialized before proceeding
    return CrewStore.initAsync(store).then(() =>
      fn({
        ...args,
        params: { ...restParams, store },
        context: CrewStore,
      }),
    );
  };

export const crewAction =
  (fn: ActionFunction<CrewContext, { store: string }>) =>
  ({ request, params }: RouterDataFunctionArgs) => {
    const { store, ...restParams } = params;

    if (!store) {
      return error(
        new Error('Missing store identifier', { cause: { params } }),
      );
    }

    return request.formData().then((formData) =>
      fn({
        params: { ...restParams, store },
        request,
        formData,
        context: CrewStore,
      }),
    );
  };

export const registrationLoader =
  (fn: LoaderFunction<RegistrationContext, { store: string }>) =>
  ({ params, ...args }: RouterDataFunctionArgs) => {
    const { store, ...restParams } = params;

    if (!store) {
      return error(
        new Error('Missing store identifier', { cause: { params } }),
      );
    }

    // since the CrewStore is getting the settings, we need to ensure it's initialized before proceeding
    return CrewStore.initAsync(store).then(() => {
      RegistrationStore.init(CrewStore.settings);

      return fn({
        ...args,
        params: { ...restParams, store },
        context: RegistrationStore,
      });
    });
  };

export const registrationAction =
  (fn: ActionFunction<RegistrationContext, { store: string }>) =>
  ({ request, params }: RouterDataFunctionArgs) => {
    const { store, ...restParams } = params;

    if (!store) {
      return error(
        new Error('Missing store identifier', { cause: { params } }),
      );
    }

    return request.formData().then((formData) =>
      fn({
        params: { ...restParams, store },
        request,
        formData,
        context: RegistrationStore,
      }),
    );
  };

export const gspLoader =
  (fn: LoaderFunction<GspContext>) =>
  ({ params, ...args }: RouterDataFunctionArgs) =>
    fn({
      ...args,
      context: GspStore,
      params,
    });

export const gspAction =
  (fn: ActionFunction<GspContext>) =>
  ({ request, params }: RouterDataFunctionArgs) =>
    request.formData().then((formData) =>
      fn({
        request,
        formData,
        context: GspStore,
        params,
      }),
    );

export const withDraftClaim =
  <
    TArgs extends DataFunctionArgs<CrewContext, { store: string }>,
    TReturn extends DataFunctionReturn,
  >(
    fn: DataFunction<
      TArgs & { claim: NonNullable<CrewContext['draftClaim']> },
      TReturn
    >,
  ) =>
  (args: TArgs) => {
    const {
      params: { draftId, idFromPlatform, claimType, store },
      context,
    } = args;

    if (!draftId || !idFromPlatform || !claimType || !store) {
      return error(
        new Error(
          'Missing URL parameters. Please provide draft ID, order ID, and claim type.',
          { cause: { params: args.params } },
        ),
      );
    }

    const claim =
      context.draftClaim && context.draftClaim.id === draftId ?
        context.draftClaim
      : context.getLineItemClaim(draftId);

    if (!claim) {
      return redirect(
        `/${store}/order/${idFromPlatform}/${claimType}`,
        StatusCodes.SEE_OTHER,
      );
    }

    context.setDraftClaim(claim);

    return fn({ ...args, claim });
  };

export const useIsAtIndex = () => {
  const { store = '' } = useParams(z.object({ store: z.string().optional() }));
  const pathMatch = useMatch(`/${store}`);

  return Boolean(pathMatch);
};

export const catchallRoute: RouteObject = {
  path: '*',
  loader: ({ params: { store = '' } }) => redirect(`/${store}`),
};
/**
 * Check the path against the current request URL and return a 200 response if it matches.
 * Otherwise, redirect to the base route.
 * This is useful for routes that are not renderable and should not be accessed directly.
 * For example, the path /order will match as /:store,
 * the app and will try to get settings for the store 'order' instead of hitting the catchall route.
 */
export const nonRenderingRouteLoader =
  (path: string | PathPattern<string>) =>
  ({ request, params }: RouterDataFunctionArgs) => {
    const pathMatch = matchPath(path, new URL(request.url).pathname);

    return pathMatch ? json({ ok: true }) : redirect(`/${params.store ?? ''}`);
  };
