import type { RouteObject } from 'react-router-dom';
import action from './return-review-action';
import Component from './return-review-component';
import ErrorBoundary from './return-review-error-component';
import loader from './return-review-loader';

export default {
  id: 'reviewReturn',
  path: 'return',
  Component,
  loader,
  action,
  ErrorBoundary,
  //! don't want to run the loader again after the action call -- e.g. it will create a new payment intent if the payment confirmation fails on a declined card
  shouldRevalidate: () => false,
} satisfies RouteObject;
