type CodeDetail = {
  url: string;
  returnToName: string;
  fromAddress: {
    line1: string;
    line2?: string | null | undefined;
    city: string;
    countryCode: string;
    postalCode: string;
    stateOrProvinceCode?: string | null | undefined;
  };
  carrier: string;
  carrierIconImgUrl: string;
  confirmation: string;
};
export function QrCode<T extends CodeDetail>({ details }: { details: T }) {
  return (
    <figure className="flex w-full flex-col items-center divide-y self-center rounded-lg border border-outline sm:flex-row sm:items-stretch sm:divide-x sm:divide-y-0">
      <div className="flex basis-1/2 items-center justify-center">
        <img
          alt="QR Code for shipping label"
          className="w-72 p-4"
          src={details.url}
        />
      </div>
      <figcaption className="flex w-full basis-1/2 items-center justify-center rounded-bl-lg rounded-br-lg rounded-tr-none bg-gray-50 sm:rounded-bl-none sm:rounded-tr-lg">
        <span className="sr-only">QR Code for shipping label</span>
        <dl className="flex flex-col items-center p-4 sm:items-start">
          <dt className="text-sm uppercase text-gray-500">Confirmation</dt>
          <dd className="mb-2">#{details.confirmation}</dd>
          <dt className="text-sm uppercase text-gray-500">Carrier</dt>
          <dd className="mb-2 flex items-center gap-1">
            <img
              aria-hidden
              alt="carrier"
              className="w-8"
              src={details.carrierIconImgUrl}
            />
            {details.carrier}
          </dd>
          <dt className="text-sm uppercase text-gray-500">To</dt>
          <dd className="mb-2">{details.returnToName}</dd>
          <dt className="text-sm uppercase text-gray-500">From</dt>
          <dd className="text-center sm:text-left">
            {details.fromAddress.line1} {details.fromAddress.line2}
            <br />
            {details.fromAddress.city},{' '}
            {details.fromAddress.stateOrProvinceCode}{' '}
            {details.fromAddress.postalCode}
            <br />
            {details.fromAddress.countryCode}
          </dd>
        </dl>
      </figcaption>
    </figure>
  );
}
