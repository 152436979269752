import { RouteObject } from 'react-router-dom';
import action from './edit-address-action';
import Component from './edit-address-component';
import loader from './edit-address-loader';

export default {
  id: 'editAddress',
  path: 'edit',
  action,
  loader,
  Component,
} satisfies RouteObject;
