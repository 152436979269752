import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import OrderOverview from './[:idFromPlatform]';

export default {
  // without the path /reorder will match as /:store and will try
  // to get settings for the store 'reorder' instead of hitting the catchall route
  path: '/:store?/reorder',
  // the loader will redirect to the base route if there is no idFromPlatform
  loader: nonRenderingRouteLoader({
    path: `/:store?/reorder/${OrderOverview.path}`,
    end: false,
  }),
  children: [OrderOverview],
} satisfies RouteObject;
