import { useMemo } from 'react';
import { matchPath, useRouteLoaderData } from 'react-router-dom';
import { z } from 'zod';
import { GspStore } from '~/stores/gsp';
import api from '~/utils/api';
import {
  ClaimType,
  CORSO_URL,
  DEFAULT_PROTECTED_ICON_IMG_URL,
  DEFAULT_UNPROTECTED_ICON_IMG_URL,
} from '~/utils/constants';
import { hasEligibleReturnResolution } from '~/utils/order';
import { crewLoader, json, redirect } from '~/utils/routing';
import { AsyncReturnType, CrewContext } from '~/utils/types';

type CrewOrder = AsyncReturnType<typeof api.fetchCrewOrder>;

export type OrderHubLoaderData = {
  trackers: NonNullable<CrewOrder['fulfillments'][number]['tracker']>[];
  existingClaims?: CrewOrder['claims'];
  isOrderFulfilled: boolean;
  wasShippingProtected: boolean;
  shippingProtectionImgIconUrl: string;
  areReturnsEnabled: boolean;
  areWarrantiesEnabled: boolean;
  isUnprotectedShippingClaimPermitted: boolean;
  pendingClaimType?: ClaimType;
};

type CrewOrderData = {
  order: CrewOrder;
  pendingClaimType?: ClaimType;
  areReturnsEnabled: boolean;
  areWarrantiesEnabled: boolean;
};

export const ID = 'crewOrder';

const pickTrackers = (order: CrewOrder) =>
  order.fulfillments.map((fulfillment) => fulfillment.tracker).filter(Boolean);

const pickExistingClaims = (order: CrewOrder) => {
  const claims = order.claims?.filter((claim) => claim.linkToCustomerUi);

  return claims?.length ? claims : undefined;
};

type GetOrderParams = {
  context: CrewContext;
  idFromPlatform: string;
};

const getOrder = (params: GetOrderParams) => {
  const { idFromPlatform, context } = params;
  const { storefrontId } = context.settings;

  return context.order?.idFromPlatform === idFromPlatform ?
      Promise.resolve(context.order)
    : api
        .fetchCrewOrder({
          params: { storefrontId },
          query: { idFromPlatform },
        })
        .then((order) => {
          context.reset();
          context.setOrder(order);

          //* prep the gsp flow with the order
          GspStore.reset();
          GspStore.setOrder(order);

          return order;
        });
};

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  store: z.string(),
});

function canProceedToWarranty(order: CrewOrder): boolean {
  // if order was created from a registration, proceed to warranty claim page
  if (order.wasCreatedFromRegistration) {
    return true;
  }

  // no valid return options exist
  const hasEligibleReturn = order.lineItems.some(hasEligibleReturnResolution);

  // no tracking exists
  const hasTracking = order.fulfillments.some(
    (fulfillment) => fulfillment.tracker,
  );

  const { wasShippingProtected } = order;

  // we can't proceed to warranty if we have a return options,
  // tracking,
  // or protection was purchased
  if (hasEligibleReturn || hasTracking || wasShippingProtected) {
    return false;
  }

  // we can proceed to warranty if we have a warranty eligible item
  return order.lineItems.some((item) => item.warranty.isEligible);
}

export default crewLoader(({ params, context, request }) => {
  const { idFromPlatform, store } = paramsSchema.parse(params);
  const {
    settings: {
      isActive,
      storeUrl = CORSO_URL,
      return: { isEnabled: areReturnsEnabled },
      warranty: { isEnabled: areWarrantiesEnabled },
    },
  } = context;

  // ? Should be moved to top level app layout loader so it is always checked?
  if (!isActive) {
    return redirect(`../../inactive?redirect=${encodeURI(storeUrl)}`);
  }

  return getOrder({
    context,
    idFromPlatform,
  })
    .then((order) => {
      // returns null when no child routes were matched => at the hub
      const atHub = !matchPath(
        // matching the path for any child routes
        { path: '/:store/order/:idFromPlatform/:claimType', end: false },
        new URL(request.url).pathname,
      );

      const shouldRedirectToWarranty =
        atHub && areWarrantiesEnabled && canProceedToWarranty(order);

      if (shouldRedirectToWarranty) {
        return redirect(ClaimType.warranty);
      }

      return json<CrewOrderData>({
        order,
        pendingClaimType: context.lineItemClaims[0]?.claimType,
        areReturnsEnabled,
        areWarrantiesEnabled,
      });
    })
    .catch(() => redirect(`/${store}`));
});

/**
 * This hook uses the `useRouteLoaderData` hook to provide
 * the order data any ancestor or descendant components
 * matching the `OrderLoader` route. In particular, it provides
 * the order data to the AppShell rather than having the AppShell query
 * the order data itself.
 */
export const useCrewOrderData = () =>
  useRouteLoaderData(ID) as CrewOrderData | undefined;

/**
 * This hook provides the data needed by the OrderHub component that is a child of this loader.
 * It uses the `useCrewOrderData` hook to get the order data and
 * then transforms it into the shape needed by the OrderHub component.
 */
export const useOrderHubData = () => {
  const data = useCrewOrderData();

  if (!data) {
    throw new Error('Order data not found');
  }
  const { order, pendingClaimType, areReturnsEnabled, areWarrantiesEnabled } =
    data;

  return useMemo(
    () =>
      ({
        trackers: pickTrackers(order),
        wasShippingProtected: order.wasShippingProtected,
        shippingProtectionImgIconUrl:
          order.wasShippingProtected ?
            (order.shippingProtectionImgIconUrl ??
            DEFAULT_PROTECTED_ICON_IMG_URL)
          : DEFAULT_UNPROTECTED_ICON_IMG_URL,
        existingClaims: pickExistingClaims(order),
        isOrderFulfilled: order.isOrderFulfilled,
        areReturnsEnabled,
        areWarrantiesEnabled,
        isUnprotectedShippingClaimPermitted:
          order.isUnprotectedShippingClaimPermitted,
        ...(pendingClaimType && { pendingClaimType }),
      }) satisfies OrderHubLoaderData,
    [order, pendingClaimType, areReturnsEnabled, areWarrantiesEnabled],
  );
};
