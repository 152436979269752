import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import Card from '~/components/card';
import Page from '~/components/page';
import { isErrorMessage } from '~/utils/schemas';

export default function ReturnReviewError() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) || !isErrorMessage(error)) {
    throw error;
  }

  return (
    <Page headline="Return Problem">
      <Card>{error.message}</Card>
    </Page>
  );
}
