import { RouteObject } from 'react-router-dom';
import action from './meta-action';
import Component from './meta-component';
import loader from './meta-loader';

export default {
  path: 'meta',
  Component,
  loader,
  action,
} satisfies RouteObject;
