import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  crewAction,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import { ActionResult } from '~/utils/types';

export const INPUT_KEY = 'quantity';

const makeFormSchema = (remainingReturnableQuantity: number) =>
  zfd.formData({
    [INPUT_KEY]: zfd.numeric(
      z.number().min(1).max(remainingReturnableQuantity),
    ),
  });

export default crewAction(
  withDraftClaim(({ formData, context, claim }) => {
    const formSchema = makeFormSchema(
      claim.lineItem.remainingReturnableQuantity,
    );
    const result = formSchema.safeParse(formData);

    if (!result.success) {
      return json<ActionResult>({
        ok: false,
        message: 'Invalid data submitted. Please try again.',
      });
    }

    context.setDraftClaim({
      ...claim,
      quantity: result.data.quantity,
    });

    return redirect('../reason', StatusCodes.SEE_OTHER);
  }),
);

export const useQuantityActionResult =
  createActionResultHook<ActionResult<typeof INPUT_KEY>>();
