import { ReactNode, useId } from 'react';
import { twMerge } from 'tailwind-merge';

type SizeVariant = 'sm' | 'md' | 'lg';
const variantStyles = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'font-bold text-gray-800 text-lg',
} satisfies Record<SizeVariant, string>;

export function DLItem({
  label,
  value,
  variant = 'md',
}: {
  label: string;
  value: string;
  variant?: SizeVariant;
}) {
  const id = useId();

  const styles = variantStyles[variant];

  return (
    <>
      <dt id={id} className={styles}>
        {label}
      </dt>
      <dd aria-labelledby={id} className={twMerge(styles, 'text-right')}>
        {value}
      </dd>
    </>
  );
}

export default function DL({
  className,
  children,
  divide,
}: {
  className?: string;
  divide?: boolean;
  children?: ReactNode;
}) {
  return (
    <dl
      className={twMerge(
        'grid grid-cols-2 text-sm *:py-1 [&>dd:first-of-type]:!pt-0 [&>dd:last-of-type]:!pb-0 [&>dt:first-of-type]:!pt-0 [&>dt:last-of-type]:!pb-0',
        divide &&
          'divide-y divide-outline *:py-2 [&>dd:first-of-type]:!border-t-0',
        className,
      )}
    >
      {children}
    </dl>
  );
}
