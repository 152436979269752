import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const {
  VITE_NODE_ENV: environment,
  VITE_SENTRY_DSN: dsn,
  VITE_BUILD_SHA: release,
} = import.meta.env;

if (environment === 'prod') {
  Sentry.init({
    environment,
    dsn,
    normalizeDepth: Infinity,
    attachStacktrace: true,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      // capture react errors
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        minReplayDuration: 15000,
        networkDetailAllowUrls: [
          window.location.origin,
          'api.corso.com',
          'api.stg.corso.com',
        ],
        networkCaptureBodies: true,
      }),
    ],
    tracesSampleRate: 0.2,
    tracePropagationTargets: [/^https:\/\/api\.corso\.com/],
    ignoreErrors: [
      'Object Not Found Matching Id', // https://github.com/getsentry/sentry-javascript/issues/3440
    ],
    release,
    // replay integration configuration
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
  });
}
