import { RouteObject } from 'react-router-dom';
import action from './reason-detail-action';
import Component from './reason-detail-component';
import loader from './reason-detail-loader';

export default {
  id: 'claimReasonDetail',
  path: 'reason/:reasonId',
  Component,
  action,
  loader,
} satisfies RouteObject;
