import Card from '~/components/card';
import Page from '~/components/page';

export default function NotProtected() {
  return (
    <Page headline="Not Protected" showHeadline={false}>
      <Card headline="Please Contact Us">
        <p>
          We found your order, but it does not have Shipping Protection, or it
          has been canceled. If you have any questions or need assistance,
          please contact us at{' '}
          <a
            className="text-primary-on-container hover:underline"
            href="mailto:support@corso.com"
          >
            support@corso.com
          </a>
          .
        </p>
      </Card>
    </Page>
  );
}
