import { Form } from 'react-router-dom';
import AddressDisplay from '~/components/address';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import LineItem from '~/components/line-item';
import MediaDisplay from '~/components/media-display';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useReviewData } from './review-loader';

export default function Review() {
  const data = useReviewData();
  const isLoading = useIsLoading();

  return (
    <Page
      headline="A Quick Review"
      title="Here are the details of your request, does everything look right?"
    >
      <Card headline="Request">
        <dl className="[&>dd]:text-gray-800 [&>dt:first-of-type]:mt-0 [&>dt]:mt-4 [&>dt]:font-semibold">
          <dt>Reason:</dt>
          <dd>{data.reason}</dd>
          {data.meta?.noteFromCustomer && (
            <>
              <dt>Comments:</dt>
              <dd>{data.meta.noteFromCustomer}</dd>
            </>
          )}
          {data.meta?.assets && data.meta.assets.length > 0 && (
            <>
              <dt>Attachments:</dt>
              <dd className="grid grid-cols-2 gap-4 pt-1 md:grid-cols-3">
                {data.meta.assets.map(({ src, name, type }) => (
                  <MediaDisplay key={name} alt={name} src={src} type={type} />
                ))}
              </dd>
            </>
          )}
        </dl>
      </Card>

      <Card headline="Items">
        {data.items.map((item) => (
          <LineItem
            key={item.id}
            variant="small"
            name={item.name ?? 'N/A'}
            imgSrc={item.imgUrl}
            quantity={item.quantity}
            price={item.unitDisplayPrice}
          />
        ))}
      </Card>

      <Card headline="Address">
        <AddressDisplay address={data.address} />
      </Card>
      <Form method="POST">
        <input type="hidden" hidden name="claim" value="create" />
        <Submit loading={isLoading}>Submit Request</Submit>
      </Form>
    </Page>
  );
}
