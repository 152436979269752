import Card from '~/components/card';
import OrderLookUpForm from '~/components/order-lookup-form';
import Page from '~/components/page';
import { FormKeys, useOrderLookupActionResult } from './gsp-lookup-action';

export default function OrderLookup() {
  const actionResult = useOrderLookupActionResult();

  return (
    <Page headline="Let's find your order">
      <Card>
        <OrderLookUpForm
          lookupIdentifierName={FormKeys.EmailOrPostalCode}
          orderNumberName={FormKeys.OrderNo}
          orderLookupLabel="Order Number"
          errorMessage={
            actionResult?.ok === false ? actionResult.message : undefined
          }
        />
      </Card>
    </Page>
  );
}
