import { RouteObject } from 'react-router-dom';
import action from './return-methods-action';
import Component from './return-methods-component';
import loader from './return-methods-loader';

export default {
  id: 'returnMethods',
  path: 'return-methods',
  action,
  loader,
  Component,
} satisfies RouteObject;
