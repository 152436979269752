import {
  ChangeEventHandler,
  FocusEventHandler,
  FormEventHandler,
  forwardRef,
  ReactNode,
  TextareaHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Field from './field';

type ValidationConstraint = Exclude<keyof ValidityState, 'valid'>;
type TextAreaPrimitiveProps = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'placeholder' | 'id'
> & {
  id: string;
  validationMessages?: Partial<Record<ValidationConstraint, string>>;
};

const TextAreaPrimitive = forwardRef<
  HTMLTextAreaElement,
  TextAreaPrimitiveProps
>(
  (
    {
      className,
      value,
      defaultValue,
      onChange,
      onBlur,
      onInvalid,
      validationMessages = {},
      rows = 4,
      ...rest
    },
    ref,
  ) => {
    const [isEmpty, setIsEmpty] = useState(!(value || defaultValue));
    const [isValid, setIsValid] = useState(true);
    // Set valid data attribute on input invalid event to style the input trigger by submitting the form
    const handleInvalid: FormEventHandler<HTMLTextAreaElement> = (event) => {
      const ta = event.currentTarget;

      setIsValid(ta.validity.valid);

      if (!ta.validity.valid) {
        const constraint = Object.keys(validationMessages).find(
          (key) => ta.validity[key as keyof ValidityState],
        ) as keyof typeof validationMessages;

        const message =
          constraint && validationMessages[constraint] ?
            validationMessages[constraint]
          : ta.validationMessage;

        /**
         * * sets the validation message that is also displayed in native popup message
         * ! this will need to be cleared before checking the validity again
         * ! because it will set the validity of `customError` to true
         */
        ta.setCustomValidity(message);
      }

      onInvalid?.(event);
    };

    const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
      const ta = event.currentTarget;

      if (!isEmpty) {
        setIsValid(ta.validity.valid);
      }

      onBlur?.(event);
    };

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      const input = event.currentTarget;
      /*
       * not really a good, consistent way to check if the input is empty (has no value),
       * especially since not allowing the placeholder
       */
      setIsEmpty(!input.value.trim());
      // !Clear the custom validity message `customError` before checking the validity again
      input.setCustomValidity('');

      // Update the validity state on change if the input is in invalid state
      if (!isValid) {
        setIsValid(input.validity.valid);
      }

      onChange?.(event);
    };
    return (
      <textarea
        {...rest}
        rows={rows}
        className={twMerge(
          'w-full rounded-md border-gray-300 pl-4 pt-5 focus:border-primary focus:ring-primary',
          'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
          'data-[invalid]:border-error data-[invalid]:focus:border-error data-[invalid]:focus:ring-error',
          className,
        )}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onInvalid={handleInvalid}
        data-empty={isEmpty ? '' : undefined}
        data-invalid={isValid ? undefined : ''}
        ref={ref}
      />
    );
  },
);

export default function TextArea({
  id,
  label,
  className,
  description,
  ...textAreaProps
}: {
  label: string;
  description?: ReactNode;
  validationMessages?: Partial<Record<ValidationConstraint, string>>;
} & TextAreaPrimitiveProps) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // This watches for changes in the input's validity and sets the error message accordingly
  useEffect(() => {
    const ta = ref.current;

    const observer = new MutationObserver(() => {
      if (!ta) return;

      if (ta.validity.valid) {
        setErrorMessage(null);
      } else {
        setErrorMessage(ta.validationMessage);
      }
    });
    if (ta) {
      observer.observe(ta, {
        attributes: true,
        attributeFilter: ['data-invalid'],
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Field.Root className="relative">
      <TextAreaPrimitive
        // eslint-disable-next-line react/jsx-props-no-spreading -- the props extend the TextArea primitive props
        {...textAreaProps}
        id={id}
        className={twMerge(className, 'peer')}
        aria-describedby={`${id}-help-text`}
        ref={ref}
      />
      <Field.Label
        className={twMerge(
          'pointer-events-none absolute left-0 ml-4 p-0 duration-100 ease-linear',
          // textarea has value
          'translate-y-1 text-xs',
          // textarea is empty and focused
          'peer-data-[empty]:peer-focus:translate-y-1 peer-data-[empty]:peer-focus:text-xs',
          // textarea is empty
          'peer-data-[empty]:translate-y-4 peer-data-[empty]:text-sm peer-data-[empty]:text-gray-500',
        )}
        htmlFor={id}
      >
        {`${label}${textAreaProps.required ? '' : ' (optional)'}`}
      </Field.Label>
      {(errorMessage || description) && (
        <Field.HelpText id={`${id}-help-text`} error={!!errorMessage}>
          {errorMessage ?? description}
        </Field.HelpText>
      )}
    </Field.Root>
  );
}
