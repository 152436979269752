import { RouteObject } from 'react-router-dom';
import Claim from './claim';
import NewClaim from './new';
import NotProtected from './not-protected';
import Component from './order-overview-component';
import {
  ID,
  orderOverviewLoader,
  protectionOrderLoader,
} from './order-overview-loader';

export default {
  // The ID is used for the useRouteLoaderData hook in loader file, need to match up.
  id: ID,
  path: ':idFromPlatform',
  loader: protectionOrderLoader,
  children: [
    {
      index: true,
      Component,
      loader: orderOverviewLoader,
    },
    Claim,
    NewClaim,
    NotProtected,
  ],
} satisfies RouteObject;
