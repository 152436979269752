import { toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { GspState, Simplify } from '~/utils/types';

type Item = NonNullable<GspState['order']>['lineItems'][number];
export type LoaderData = {
  items: Simplify<Item & { unitDisplayPrice: string }>[];
  selectedItemIds: number[];
};

export default gspLoader(({ params: { store = '' }, context }) => {
  if (!context.order || !context.reason) {
    return redirect(`/${store}`);
  }

  const formatPrice = toCurrency(context.order.currencyCode);

  return json<LoaderData>({
    items: context.order.lineItems
      .filter((item) => !item.isCorsoProduct)
      .map((item) => ({
        ...item,
        unitDisplayPrice: formatPrice(item.unitPrice),
      })),
    selectedItemIds: context.itemIds ?? [],
  });
});

export const useItemsLoaderData = createLoaderDataHook<LoaderData>();
