import { json, redirect, RouteObject } from 'react-router-dom';
import { registrationLoader } from '~/utils/routing';
import Overview from './overview';
import Product from './products';
import action from './register-action';
import Component from './register-component';
import loader from './register-loader';

export default {
  id: 'register',
  path: 'register',
  loader: registrationLoader(({ context }) =>
    context.settings.registrations.isEnabled ?
      json({
        ok: true,
      })
    : redirect('..'),
  ),
  children: [
    {
      action,
      loader,
      Component,
      index: true,
    },
    Product,
    Overview,
  ],
} satisfies RouteObject;
