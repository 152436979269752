import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  Close as DialogClose,
  Content as DialogContent,
  Overlay as DialogOverlay,
  Portal as DialogPortal,
  Root as DialogRoot,
  Title as DialogTitle,
} from '@radix-ui/react-dialog';
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  PropsWithChildren,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Button from './button';

type ModalProps = PropsWithChildren<{
  open: boolean;
  onClose: () => void;
  className?: string;
}>;

function Modal({ open, onClose, children, className }: ModalProps) {
  return (
    <DialogRoot
      modal
      open={open}
      onOpenChange={(isOpen) => !isOpen && onClose()}
    >
      <DialogPortal>
        <DialogOverlay className="data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 fixed inset-0 z-50 bg-black/80 backdrop-blur-sm" />
        <DialogContent
          className={twMerge(
            'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] fixed left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 duration-200',
            'grid max-h-full w-full max-w-lg grid-flow-dense gap-4 overflow-y-auto rounded-lg rounded-t-lg border border-outline bg-white p-6 shadow-lg',
            className,
          )}
          //! setting aria-describedby to undefined must use the Modal title
          aria-describedby={undefined}
        >
          {children}
          <DialogClose asChild>
            <Button variant="outlined" className="absolute right-2 top-2 p-2">
              <span className="sr-only">Close</span>
              <XMarkIcon className="size-6 text-gray-500" />
            </Button>
          </DialogClose>
        </DialogContent>
      </DialogPortal>
    </DialogRoot>
  );
}

function ModalHeader({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <div className={twMerge('flex justify-between', className)}>{children}</div>
  );
}

const ModalTitle = forwardRef<
  ElementRef<typeof DialogTitle>,
  ComponentPropsWithoutRef<typeof DialogTitle>
>(({ className, ...props }, ref) => (
  <DialogTitle
    ref={ref}
    className={twMerge(
      'text-base font-semibold leading-6 text-gray-900',
      className,
    )}
    // eslint-disable-next-line react/jsx-props-no-spreading -- modal title props are the same as the dialog title
    {...props}
  />
));
ModalTitle.displayName = 'ModalTitle';

function ModalFooter({
  children,
  className,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <div className={twMerge('flex justify-end gap-4', className)}>
      {children}
    </div>
  );
}

export { Modal, ModalFooter, ModalHeader, ModalTitle };
