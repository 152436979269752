import action from './address-action';
import Component from './address-component';
import loader from './address-loader';
import Edit from './edit';

export default {
  id: 'address',
  path: 'address',
  children: [
    {
      index: true,
      action,
      loader,
      Component,
    },
    Edit,
  ],
};
