import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import ProtectionClaim from './[:claimId]';

export default {
  path: 'claim',
  loader: nonRenderingRouteLoader({
    path: `/:store?/reorder/:idFromPlatform/claim/${ProtectionClaim.path}`,
    end: false,
  }),
  children: [ProtectionClaim],
} satisfies RouteObject;
