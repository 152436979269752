import { RouteObject } from 'react-router-dom';
import { nonRenderingRouteLoader } from '~/utils/routing';
import ClaimOverview from './[:claimExternalId]';

export default {
  path: 'overview',
  loader: nonRenderingRouteLoader({
    path: `/:store/claim/overview/${ClaimOverview.path}`,
    end: false,
  }),
  children: [ClaimOverview],
} satisfies RouteObject;
