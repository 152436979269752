import { Form } from 'react-router-dom';
import AddressFields from '~/components/address-fields';
import Submit from '~/components/button-submit';
import Card from '~/components/card';
import Page from '~/components/page';
import { useIsLoading } from '~/utils/routing';
import { useAddressData } from './edit-address-loader';

export default function Address() {
  const { address } = useAddressData();
  const isLoading = useIsLoading();

  return (
    <Page headline="Update Address">
      <Card>
        <Form method="post">
          <AddressFields address={address} />
          <Submit className="mt-6" loading={isLoading}>
            Continue
          </Submit>
        </Form>
      </Card>
    </Page>
  );
}
